import { InputData as InputDataGrpc } from '@policyfly/protobuf'
import { revertSchema } from '@policyfly/schema'
import { arrayIncludes, isAPIPayloadResponse } from '@policyfly/utils/type'
import get from 'lodash-es/get'
import { unref } from 'vue'

import { useDynamicStore } from '@/composables/dynamicStore'
import { useProgramDataStore } from '@/stores/programData'

import { WORKING_COPIES_KEY, WRITABLE_SOURCES } from '@/constants'
import { devtools } from '@/plugins/devtools/api'
import { formatValue } from '@/utils/formatter'
import {
  getBinderResponse,
  getExternalResponse,
  getIssueResponse,
  getLicenseResponse,
  getLocalResponse,
  getMetaResponse,
  getNestedResponse,
  getParentResponse,
  getPath,
  getProtobufResponse,
  getQuoteResponse,
  getRatingResponse,
  getRecentResponse,
} from '@/utils/responses'

import type { PayloadLike } from '@/lib/Payload'
import type { FormSource } from '@policyfly/schema/types/shared/formSource'
import type {
  InputData,
  InputDataPathResolver,
  InputDataProtobuf,
  InputDataResolved,
  InputDataResolver,
} from '@policyfly/schema/types/shared/inputData'
import type { StringWithPlaceholders } from '@policyfly/schema/types/shared/stringWithPlaceholders'
import type { ComputedRef } from 'vue'

type ResolveData<S extends FormSource = FormSource> = InputDataResolved<S> | InputData<S> | InputDataGrpc
const LOCAL_SOURCES = ['local', 'temp', 'computed'] as const

/**
 * Resolves provided data according to the resolveData specified.
 * Useful for resolving something completely unrelated to the current context.
 *
 * If you want to resolve something within a component context use the methods
 * `resolveSiblingInputData` or `resolveNestedInputData` from the `useInputData` composable.
 *
 * @example
 * resolveInputData({ path: 'path1', source: 'local' }, { path: 'path2', source: 'local' }) // { path: 'path1.path2', source: 'local' }
 */
export function resolveInputData<S extends FormSource> (
  resolveData: ResolveData | ComputedRef<ResolveData> | null,
  data: InputData<S> | InputDataGrpc,
): InputDataResolved<S> {
  data = revertInputData(data)
  const dataPath = getPath(data.path)
  const resolved = {
    ...data,
    path: dataPath,
  }
  if (resolveData) {
    const unrefData = revertInputData(unref(resolveData))
    const dataSource = resolved.source
    const resolveSource = unrefData.source
    if (
      dataSource === resolveSource ||
      // treat any local-like sources as the same
      (arrayIncludes(LOCAL_SOURCES, dataSource) && arrayIncludes(LOCAL_SOURCES, resolveSource))
    ) {
      // copy any extra properties from resolveData that are missing in data
      Object.assign(resolved, unrefData, data, { path: dataPath })
      // nest paths
      const resolvedUnrefPath = getPath(unrefData.path)
      if (resolvedUnrefPath && resolved.path && resolved.path.startsWith(resolvedUnrefPath)) {
        devtools.configWarning({
          type: 'path',
          title: 'Possible duplicate resolver',
          description: 'Resolver path starts with data path',
          context: JSON.stringify({ resolver: resolvedUnrefPath, path: resolved.path }),
        })
      }
      resolved.path = [resolvedUnrefPath, resolved.path].filter((v) => !!v).join('.')
    }
  }

  // handle any special syntaxes that can be resolved immediately
  const parts = resolved.path.split('.')
  const resultParts: string[] = []
  for (const part of parts) {
    if (part === '(parent)') {
      if (resultParts.length > 0) {
        resultParts.pop()
        // skip over WORKING_COPIES_KEY to give working copies and an array index the same level of depth
        if (resultParts.length && resultParts[resultParts.length - 1] === WORKING_COPIES_KEY) {
          resultParts.pop()
        }
      } else {
        devtools.configWarning({
          type: 'path',
          title: 'Used a (parent) path but already at root',
          description: resolved.path,
        })
      }
    } else if (part === '(root)') {
      if (resultParts.length > 0) {
        resultParts.splice(0)
      } else {
        devtools.configWarning({
          type: 'path',
          title: 'Used a (root) path but already at root',
          description: resolved.path,
        })
      }
    } else if (part === '(quote)') {
      const [part0, part1, part2] = resultParts
      if (part0 === 'quotes' && part1 === 'quotes' && /\d+/.test(part2)) {
        resultParts.splice(3)
      } else {
        devtools.configWarning({
          type: 'path',
          title: 'Used a (quote) path but not in a quote, should start with "quotes.quotes.X"',
          description: resolved.path,
        })
      }
    } else if (part === '(coverage)') {
      const lastCoverageIndex = resultParts.findLastIndex((val, index, arr) => {
        return index !== arr.length - 1 &&
          val === 'coverages' &&
          /\d+/.test(arr[index + 1])
      })
      if (lastCoverageIndex === -1) {
        devtools.configWarning({
          type: 'path',
          title: 'Used a (coverage) path but not in a coverage, should contain "coverages.X"',
          description: resolved.path,
        })
      } else {
        // + 2 to also include the `coverages.X`
        resultParts.splice(lastCoverageIndex + 2)
      }
    } else {
      resultParts.push(part)
    }
  }
  resolved.path = resultParts.join('.')

  return resolved as InputDataResolved<S>
}

/**
 * Creates a resolver function with the passed in `resolveData`.
 * If you want a "blank" data resolver that only normalizes the data and does not resolve it use {@link blankInputDataResolver} instead.
 *
 * See {@link resolveInputData}.
 *
 * @example
 * const resolver = createInputDataResolver({ path: 'path1', source: 'local' })
 * resolver({ path: 'path2', source: 'local' }) // { path: 'path1.path2', source: 'local' }
 */
export function createInputDataResolver (resolveData: ResolveData | ComputedRef<ResolveData> | null): InputDataResolver {
  return resolveInputData.bind(null, resolveData) as InputDataResolver
}

/**
 * Creates a resolver function with the passed in `resolveData` that takes a path and always resolves it with the same source.
 *
 * See {@link resolveInputData}.
 *
 * @example
 * const resolver = createInputDataPathResolver({ path: 'path1', source: 'local' })
 * resolver('path2') // { path: 'path1.path2', source: 'local' }
 */
export function createInputDataPathResolver<S extends FormSource> (
  resolveData: ResolveData | ComputedRef<ResolveData>,
): InputDataPathResolver<S> {
  const resolver = resolveInputData.bind(null, resolveData) as InputDataResolver
  return (path) => resolver({ ...revertInputData(unref(resolveData)), path } as InputData<S>)
}

/**
 * A data input resolver that only normalizes the data and does not resolve it relative to anything.
 *
 * See {@link createInputDataResolver}.
 */
export const blankInputDataResolver = createInputDataResolver(null)

const nonWritableAccess: NonNullable<InputDataProtobuf<string>['access']>[] = ['readonly', 'saved']
/**
 * Returns `true` if the passed in {@link InputData} is writable.
 */
export function isWritableInputData (inputData: InputData<FormSource> | undefined): boolean {
  if (!inputData) return false
  if (!arrayIncludes(WRITABLE_SOURCES, inputData.source)) return false
  if ('access' in inputData && arrayIncludes(nonWritableAccess, inputData.access)) return false
  return true
}

/**
 * @deprecated Temporary measure to support both formats of InputData.
 */
function revertInputData<S extends FormSource> (inputData: ResolveData<S>): InputData<S> {
  if (typeof inputData.source === 'string') return inputData
  return revertSchema<InputDataGrpc, InputData<S>>(InputDataGrpc, inputData)
}

interface GetInputDataOptions {
  /**
   * **NOTE: Only relevant on non-protobuf programs.**
   *
   * Whether to return the whole value, or just the nested `.v` of a response.
   */
  whole?: boolean
  /**
   * The resolver to run against the inputData.
   *
   * @default
   * blankInputDataResolver
   */
  resolver?: InputDataResolver | null
  /**
   * The {@link PayloadLike payload} to use when using sources that read from it.
   */
  payload?: PayloadLike | null
}
/**
 * Resolves the passed in data and then returns the value at that data point.
 */
export function getInputData<T> (inputData: InputData<FormSource> | InputDataGrpc, options?: GetInputDataOptions): T | null | undefined {
  const resolver = options?.resolver ?? blankInputDataResolver
  const resolvedData = resolver(inputData)
  const whole = !!options?.whole
  switch (resolvedData.source) {
    case 'responses':
      return getNestedResponse(resolvedData.path, whole) as T | undefined

    case 'programData': {
      const programDataStore = useProgramDataStore()
      const value = programDataStore.getProgramData(resolvedData.path)
      return (isAPIPayloadResponse(value) ? value.v : value) as T | undefined
    }

    case 'quote':
      return getQuoteResponse(resolvedData.path, whole) as T | undefined

    case 'rating':
      return getRatingResponse(resolvedData.path)

    case 'license':
      return getLicenseResponse(resolvedData.path, whole)

    case 'meta':
      return getMetaResponse(resolvedData.path, whole)

    case 'issue':
      return getIssueResponse(resolvedData.path, whole) as T | undefined

    case 'binder':
      return getBinderResponse(resolvedData.path, whole) as T | undefined

    case 'parent':
      return getParentResponse(resolvedData.path) as T | undefined

    case 'local':
    case 'temp':
    case 'computed':
      return getLocalResponse(options?.payload, resolvedData.path) as T | undefined

    case 'external':
      return getExternalResponse(options?.payload, resolvedData.path) as T | undefined

    case 'path':
      return resolvedData.path as T

    case 'store': {
      const { store: dynamicStore } = useDynamicStore(resolvedData.store)
      const value = get(dynamicStore, resolvedData.path)
      return (isAPIPayloadResponse(value) ? value.v : value) as T | undefined
    }

    case 'recent':
      return getRecentResponse(resolvedData.path) as T | undefined

    case 'pb':
      return getProtobufResponse(resolvedData.path, resolvedData.access)

    default: {
      const invalidArgs: never = resolvedData
      devtools.configWarning({
        type: 'field',
        title: 'Invalid FormSource provided.',
        description: invalidArgs,
      })
      return null
    }
  }
}

interface ParseStringWithPlaceholdersOptions extends GetInputDataOptions {
  /**
   * Will force all placeholders to display this string instead of their actual value.
   */
  forcedPlaceholder?: string | null
}

/**
 * Parses a {@link StringWithPlaceholders} to replace all placeholders with live values.
 */
export function parseStringWithPlaceholders<S extends FormSource> (
  definition: StringWithPlaceholders<S>,
  options?: ParseStringWithPlaceholdersOptions,
): string {
  return definition.text
    .split(/\{(.*?)\}/)
    .map((s, i) => {
      if (i % 2 === 0) return s
      if (options?.forcedPlaceholder) return options.forcedPlaceholder
      const valueInfo = definition.values?.[s]
      if (!valueInfo) {
        console.warn(`No value information specified for placeholder: ${s}`)
        return ''
      }
      const val = getInputData(valueInfo.data, options)
      return formatValue(val, valueInfo.formatter ?? 'default')
    })
    .join('')
}
