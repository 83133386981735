import {
  CommentServiceClient,
  CommentVisibility,
  ListCommentRequest,
  CommentListIDType,
  ListCommentResponse,
  CreateCommentRequest,
  CreateCommentResponse,
  Attachment,
  DeleteCommentRequest,
  DeleteCommentResponse,
  UpdateCommentRequest,
  ReadCommentRequest,
  ToggleVisibilityRequest,
  ToggleVisibilityResponse,
  MarkReadRequest,
  MarkReadResponse,
  MassMarkReadRequest,
  MassMarkReadResponse,
  ReadCommentResponse,
  AttachmentVisibility,
  User as CoreUser,
} from '@policyfly/protobuf'
import { computed } from 'vue'

import { usePermission } from '@/composables/permission'
import { useAuthenticationStore } from '@/stores/authentication'

import { api } from '@/api'
import { devtools } from '@/plugins/devtools/api'
import { commentToFeedEventLog, patrickUserToCoreUser, patrickUserToMention } from '@/utils/protobuf'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'
import type { Comment } from '@policyfly/protobuf'
import type { User } from '@policyfly/protobuf/patrick'
import type { FeedEventLog } from 'types/events'
import type { UnifiedMembershipUser } from 'types/user'

export interface CommentResponse {
  comment?: Comment
  json: FeedEventLog
}

export interface CommentApiEndpoints {
  /**
   * Add a new comment to an application or policy.
   */
  create: (params: {
    applicationId?: string
    policyId?: string
    comment: string
    visibility?: CommentVisibility
    files?: File[]
    mentions?: User[]
  }) => Promise<CommentResponse>
  /**
   * Soft delete a comment.
   */
  delete: (id: string) => Promise<void>
  /**
   * Fetch a single comment.
   */
  read: (id: string) => Promise<CommentResponse>
  /**
   * Update properties of an existing comment.
   */
  update: (id: string, comment: Partial<Comment>) => Promise<CommentResponse>
  /**
   * Update the visibility of a comment.
   */
  updateVisibility: (id: string, visibility: CommentVisibility) => Promise<CommentResponse>
  /**
   * Fetch all comments for an application or policy.
   */
  list: (id: string, type?: CommentListIDType, visibility?: CommentVisibility) => Promise<{
    comments?: Comment[]
    json: FeedEventLog[]
  }>
  /**
   * Mark a single comment as read.
   */
  markRead: (id: string) => Promise<CommentResponse>
  /**
   * Mark all comments for an application or policy as read.
   */
  markAllRead: (params: { applicationId: string } | { policyId: string }) => Promise<{
    comments?: Comment[]
    json: FeedEventLog[]
  }>
}

/**
 * Creates endpoints related to Comments.
 */
export const createCommentEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<CommentApiEndpoints> => {
  const commentServiceClient = new CommentServiceClient(params.transport)
  const authenticationStore = useAuthenticationStore()

  const { checkPermission } = usePermission()

  const userVisibility = computed(() => {
    if (authenticationStore.userRole === 'SUPERUSER') return CommentVisibility.COMMENT_VISIBILITY_STAFF
    if (authenticationStore.isInternalRole) return CommentVisibility.COMMENT_VISIBILITY_RESTRICTED
    return CommentVisibility.COMMENT_VISIBILITY_STANDARD
  })

  const createDjango: CommentApiEndpoints['create'] = async ({
    applicationId,
    policyId,
    comment: body,
    visibility = CommentVisibility.COMMENT_VISIBILITY_STANDARD,
    files,
    mentions,
  }) => {
    const comment = {
      ...visibility === CommentVisibility.COMMENT_VISIBILITY_RESTRICTED ? { restricted_visibility: true } : {},
      ...(mentions && mentions.length) ? { mentioned_users: mentions.map(patrickUserToMention) } : {},
    }

    if (files?.length) {
      const payload = { ...comment, application: applicationId, comment_body: body, file: files[0] }
      const formData = new FormData()
      Object.entries(payload)
        .forEach(([key, value]: [string, string | number | boolean | File | Pick<UnifiedMembershipUser, 'email' | 'first_name' | 'last_name'>[] | undefined]) => {
          if (Array.isArray(value)) formData.append(key, JSON.stringify(value))
          else if (value !== undefined) formData.append(key, key === 'file' ? value as File : String(value))
        })

      const { data } = await api.applications.upload({ body: formData, query: {} })
      return {
        json: data.EventLog!,
      }
    }

    const { data } = await api.applications.comment({ path: { pk: +(applicationId ?? +policyId!) }, body: { ...comment, body } })
    return {
      json: data.Application.events[0],
    }
  }
  const createGrpc: CommentApiEndpoints['create'] = async ({
    applicationId,
    policyId,
    comment: content,
    visibility = CommentVisibility.COMMENT_VISIBILITY_STANDARD,
    files,
    mentions,
  }) => {
    const author = CoreUser.clone(authenticationStore.coreUser)
    const request = CreateCommentRequest.create({
      comment: {
        applicationId,
        policyId,
        attachments: files && files[0]
          ? [Attachment.create({
              data: new Uint8Array(await files[0].arrayBuffer()),
              metadata: {
                applicationIds: applicationId ? [applicationId] : [],
                policyId,
                programId: String(authenticationStore.programId),
                name: files[0].name,
                mimeType: files[0].type,
                sizeBytes: String(files[0].size),
                visibility: visibility === CommentVisibility.COMMENT_VISIBILITY_RESTRICTED
                  ? AttachmentVisibility.Visibility_Restricted
                  : undefined,
                owner: author,
              },
            })]
          : undefined,
        author,
        content,
        mentions: mentions?.map(patrickUserToCoreUser),
        visibility,
        readable: !checkPermission('commentInternal'),
      },
    })
    const { response } = await commentServiceClient.create(request)

    devtools.logGrpc({
      description: 'Create Comment',
      messages: [
        { type: CreateCommentRequest, key: 'request', message: request },
        { type: CreateCommentResponse, key: 'response', message: response },
      ],
    })

    return await readGrpc(response.id)
  }

  const deleteDjango: CommentApiEndpoints['delete'] = async () => {
    throw new Error('Django programs do not support deleting comments')
  }
  const deleteGrpc: CommentApiEndpoints['delete'] = async (id) => {
    const request = DeleteCommentRequest.create({ id, soft: true })
    const { response } = await commentServiceClient.delete(request)

    devtools.logGrpc({
      description: 'Delete Comment',
      messages: [
        { type: DeleteCommentRequest, key: 'request', message: request },
        { type: DeleteCommentResponse, key: 'response', message: response },
      ],
    })
  }

  const readDjango: CommentApiEndpoints['read'] = async () => {
    throw new Error('Django programs do not support reading comments')
  }
  const readGrpc: CommentApiEndpoints['read'] = async (id) => {
    const request = ReadCommentRequest.create({ id })
    const { response } = await commentServiceClient.read(request)

    devtools.logGrpc({
      description: 'Read Comment',
      messages: [
        { type: ReadCommentRequest, key: 'request', message: request },
        { type: ReadCommentResponse, key: 'response', message: response },
      ],
    })

    return {
      comment: response.comment,
      json: commentToFeedEventLog(response.comment!),
    }
  }

  const updateDjango: CommentApiEndpoints['update'] = async () => {
    throw new Error('Django programs do not support editing comments')
  }
  const updateGrpc: CommentApiEndpoints['update'] = async (id, partialComment) => {
    const { comment: readComment } = await readGrpc(id)
    const comment = { ...readComment!, ...partialComment }
    const request = UpdateCommentRequest.create({ comment })
    const { response } = await commentServiceClient.update(request)

    devtools.logGrpc({
      description: 'Update Comment',
      messages: [
        { type: UpdateCommentRequest, key: 'request', message: request },
        { type: DeleteCommentResponse, key: 'response', message: response },
      ],
    })

    return {
      comment,
      json: commentToFeedEventLog(comment),
    }
  }

  const updateVisibilityDjango: CommentApiEndpoints['updateVisibility'] = async () => {
    throw new Error('Django programs do not support editing comments')
  }
  const updateVisibilityGrpc: CommentApiEndpoints['updateVisibility'] = async (id, visibility) => {
    const request = ToggleVisibilityRequest.create({ id, visibility })
    const { response } = await commentServiceClient.toggleVisibility(request)

    devtools.logGrpc({
      description: 'Update Comment Visibility',
      messages: [
        { type: ToggleVisibilityRequest, key: 'request', message: request },
        { type: ToggleVisibilityResponse, key: 'response', message: response },
      ],
    })

    return readGrpc(id)
  }

  const listDjango: CommentApiEndpoints['list'] = async () => {
    // Django comments are returned as part of Application.events
    return { json: [] }
  }
  const listGrpc: CommentApiEndpoints['list'] = async (
    id,
    type = CommentListIDType.COMMENT_LIST_ID_TYPE_POLICY,
    visibility,
  ) => {
    const request = ListCommentRequest.create({
      id,
      type,
      visibility: visibility ?? userVisibility.value,
    })
    const { response } = await commentServiceClient.list(request)

    devtools.logGrpc({
      description: 'List Comments',
      messages: [
        { type: ListCommentRequest, key: 'request', message: request },
        { type: ListCommentResponse, key: 'response', message: response },
      ],
    })

    return {
      comments: response.comments,
      json: response.comments.map(commentToFeedEventLog),
    }
  }

  const markReadDjango: CommentApiEndpoints['markRead'] = async (id) => {
    const { data } = await api.comments.mark_read({ path: { pk: +id } })

    return {
      json: data.EventLog,
    }
  }
  const markReadGrpc: CommentApiEndpoints['markRead'] = async (id) => {
    const request = MarkReadRequest.create({ id, user: CoreUser.clone(authenticationStore.coreUser) })
    const { response } = await commentServiceClient.markRead(request)

    devtools.logGrpc({
      description: 'Mark Comment Read',
      messages: [
        { type: MarkReadRequest, key: 'request', message: request },
        { type: MarkReadResponse, key: 'response', message: response },
      ],
    })

    return readGrpc(id)
  }

  const markAllReadDjango: CommentApiEndpoints['markAllRead'] = async (payload) => {
    const { data } = 'policyId' in payload
      ? await api.comments.mark_policy_read({ path: { policy_pk: +payload.policyId } })
      : await api.comments.mark_application_read({ path: { app_pk: +payload.applicationId } })

    return {
      json: data.Events || [],
    }
  }
  const markAllReadGrpc: CommentApiEndpoints['markAllRead'] = async (payload) => {
    const request = MassMarkReadRequest.create({ user: CoreUser.clone(authenticationStore.coreUser), ...payload })
    const { response } = await commentServiceClient.massMarkRead(request)

    devtools.logGrpc({
      description: 'Mark All Comments Read',
      messages: [
        { type: MassMarkReadRequest, key: 'request', message: request },
        { type: MassMarkReadResponse, key: 'response', message: response },
      ],
    })

    return 'policyId' in payload
      ? listGrpc(payload.policyId, CommentListIDType.COMMENT_LIST_ID_TYPE_POLICY)
      : listGrpc(payload.applicationId, CommentListIDType.COMMENT_LIST_ID_TYPE_APPLICATION)
  }

  return {
    django: {
      create: createDjango,
      delete: deleteDjango,
      read: readDjango,
      update: updateDjango,
      updateVisibility: updateVisibilityDjango,
      markRead: markReadDjango,
      markAllRead: markAllReadDjango,
      list: listDjango,
    },
    grpc: {
      create: createGrpc,
      delete: deleteGrpc,
      read: readGrpc,
      update: updateGrpc,
      updateVisibility: updateVisibilityGrpc,
      markRead: markReadGrpc,
      markAllRead: markAllReadGrpc,
      list: listGrpc,
    },
  }
}
