import {
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  PatrickServiceClient,
  ValidateUserRequest,
  ValidateUserResponse,
  UpdateAccountRequest,
  UpdateAccountResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChallengeDeviceRequest,
  ChallengeDeviceResponse,
  DeleteDeviceRequest,
  DeleteDeviceResponse,
  ProvisionDeviceRequest,
  ProvisionDeviceResponse,
  RegisterDeviceRequest,
  RegisterDeviceResponse,
  UpdateDeviceRequest,
  UpdateDeviceResponse,
  AccountResponse,
  AccountRequest,
  RequestPasswordResetRequest,
  RequestPasswordResetResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  ResendUserInviteRequest,
  ResendUserInviteResponse,
  ValidateInvitedUserRequest,
  ValidateInvitedUserResponse,
  ActivateUserRequest,
  ActivateUserResponse,
  ListInternalUsersRequest,
  ListInternalUsersResponse,
  CreateInternalUserRequest,
  CreateInternalUserResponse,
  DeleteInternalUserRequest,
  DeleteInternalUserResponse,
  GetInternalUserRequest,
  GetInternalUserResponse,
  UpdateInternalUserRequest,
  UpdateInternalUserResponse,
  ListUsersRequest,
  ListUsersResponse,
  GetDevicesRequest,
  GetDevicesResponse,
} from '@policyfly/protobuf/patrick'

import { devtools } from '@/plugins/devtools/api'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'

export interface UserApiEndpoints {
  /**
   * Validates that a user has an active account.
   *
   * @returns Information about available 2FA devices for this account.
   */
  validateUser: (request: ValidateUserRequest) => Promise<ValidateUserResponse>
  /**
   * Authenticates a user with the provided login information.
   * Will update the stored API tokens for for any future requests.
   */
  login: (request: LoginRequest) => Promise<LoginResponse>
  /**
   * Sets up the user account for the first time and logs them in.
   * Will update the stored API tokens for for any future requests.
   *
   * @see {@link PatrickServiceClient.activateUser activateUser}.
   */
  setupAccount: (request: ActivateUserRequest) => Promise<ActivateUserResponse>
  /**
   * Tries to log the user out of the application.
   * Errors are simply ignored and not thrown.
   *
   * Tokens will be cleared from storage regardless of the result.
   */
  logout: () => Promise<void>
  /**
   * Updates the user's account information.
   */
  updateAccount: (request: UpdateAccountRequest) => Promise<UpdateAccountResponse>
  /**
   * Updates a user's password.
   */
  changePassword: (request: ChangePasswordRequest) => Promise<ChangePasswordResponse>
  /**
   * @see {@link PatrickServiceClient.challengeDevice challengeDevice}.
   */
  challengeDevice: (request: ChallengeDeviceRequest) => Promise<ChallengeDeviceResponse>
  /**
   * @see {@link PatrickServiceClient.deleteDevice deleteDevice}.
   */
  deleteDevice: (request: DeleteDeviceRequest) => Promise<DeleteDeviceResponse>
  /**
   * @see {@link PatrickServiceClient.provisionDevice provisionDevice}.
   */
  provisionDevice: (request: ProvisionDeviceRequest) => Promise<ProvisionDeviceResponse>
  /**
   * @see {@link PatrickServiceClient.registerDevice registerDevice}.
   */
  registerDevice: (request: RegisterDeviceRequest) => Promise<RegisterDeviceResponse>
  /**
   * @see {@link PatrickServiceClient.updateDevice updateDevice}.
   */
  updateDevice: (request: UpdateDeviceRequest) => Promise<UpdateDeviceResponse>
  /**
   * Gets the current user's account information.
   */
  account: (request: AccountRequest) => Promise<AccountResponse>
  /**
   * @see {@link PatrickServiceClient.requestPasswordReset requestPasswordReset}.
   */
  requestPasswordReset: (request: RequestPasswordResetRequest) => Promise<RequestPasswordResetResponse>
  /**
   * @see {@link PatrickServiceClient.resetPassword resetPassword}.
   */
  resetPassword: (request: ResetPasswordRequest) => Promise<ResetPasswordResponse>
  /**
   * @see {@link PatrickServiceClient.resendUserInvite resendUserInvite}.
   */
  resendUserInvite: (request: ResendUserInviteRequest) => Promise<ResendUserInviteResponse>
  /**
   * @see {@link PatrickServiceClient.validateInvitedUser validateInvitedUser}.
   */
  validateInvitedUser: (request: ValidateInvitedUserRequest) => Promise<ValidateInvitedUserResponse>
  /**
   * @see {@link PatrickServiceClient.listInternalUsers listInternalUsers}.
   */
  listInternalUsers: (request: ListInternalUsersRequest) => Promise<ListInternalUsersResponse>
  /**
   * @see {@link PatrickServiceClient.getInternalUser getInternalUser}.
   */
  getInternalUser: (request: GetInternalUserRequest) => Promise<GetInternalUserResponse>
  /**
   * @see {@link PatrickServiceClient.createInternalUser createInternalUser}.
   */
  createInternalUser: (request: CreateInternalUserRequest) => Promise<CreateInternalUserResponse>
  /**
   * @see {@link PatrickServiceClient.updateInternalUser updateInternalUser}.
   */
  updateInternalUser: (request: UpdateInternalUserRequest) => Promise<UpdateInternalUserResponse>
  /**
   * @see {@link PatrickServiceClient.deleteInternalUser deleteInternalUser}.
   */
  deleteInternalUser: (request: DeleteInternalUserRequest) => Promise<DeleteInternalUserResponse>
  /**
   * @see {@link PatrickServiceClient.listUsers listUsers}.
   */
  listUsers: (request: ListUsersRequest) => Promise<ListUsersResponse>
  /**
   * @see {@link PatrickServiceClient.getDevices getDevices}.
   */
  getDevices: (request: GetDevicesRequest) => Promise<GetDevicesResponse>
}

/**
 * Creates endpoints related to the Application workflow.
 */
export const createUserEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<UserApiEndpoints> => {
  const patrickServiceClientAuth = new PatrickServiceClient(params.transport)
  const patrickServiceClientAnon = new PatrickServiceClient(params.transportAnonymous)

  const validateUser: UserApiEndpoints['validateUser'] = async ({ email, password }) => {
    const request = ValidateUserRequest.create({ email, password })
    const { response } = await patrickServiceClientAnon.validateUser(request)

    devtools.logGrpc({
      description: 'Validate User',
      messages: [
        { type: ValidateUserRequest, key: 'request', message: request },
        { type: ValidateUserResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  function loadTokens (response: LoginResponse | ActivateUserResponse): void {
    params.refreshToken.value = response.jwt?.refresh ?? ''
    params.accessToken.value = response.jwt?.access ?? ''

    const expirationDate = new Date()
    expirationDate.setHours(expirationDate.getHours() + response.sessionLength! / 60)
    params.spaToken.value = response.spaToken ?? null
    params.spaTokenExpiration.value = expirationDate.toISOString()
  }

  const login: UserApiEndpoints['login'] = async (request) => {
    const { response } = await patrickServiceClientAnon.login(request)

    devtools.logGrpc({
      description: 'Login',
      messages: [
        { type: LoginRequest, key: 'request', message: request },
        { type: LoginResponse, key: 'response', message: response },
      ],
    })

    if (!response.tokenValidated) throw new Error('Invalid token.')

    loadTokens(response)

    return response
  }

  const setupAccount: UserApiEndpoints['setupAccount'] = async (request) => {
    const { response } = await patrickServiceClientAnon.activateUser(request)

    devtools.logGrpc({
      description: 'Activate User',
      messages: [
        { type: ActivateUserRequest, key: 'request', message: request },
        { type: ActivateUserResponse, key: 'response', message: response },
      ],
    })

    loadTokens(response)

    return response
  }

  const logout: UserApiEndpoints['logout'] = async () => {
    try {
      await patrickServiceClientAuth.logout(LogoutRequest.create())
    } catch {
      // ignore errors
    } finally {
      params.logout()
    }
  }

  const updateAccount: UserApiEndpoints['updateAccount'] = async (request) => {
    const { response } = await patrickServiceClientAuth.updateAccount(request)

    devtools.logGrpc({
      description: 'Update Account',
      messages: [
        { type: UpdateAccountRequest, key: 'request', message: request },
        { type: UpdateAccountResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const changePassword: UserApiEndpoints['changePassword'] = async (request) => {
    const { response } = await patrickServiceClientAuth.changePassword(request)

    devtools.logGrpc({
      description: 'Change Password',
      messages: [
        { type: ChangePasswordRequest, key: 'request', message: request },
        { type: ChangePasswordResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const challengeDevice: UserApiEndpoints['challengeDevice'] = async (request) => {
    const { response } = await patrickServiceClientAnon.challengeDevice(request)

    devtools.logGrpc({
      description: 'Challenge Device',
      messages: [
        { type: ChallengeDeviceRequest, key: 'request', message: request },
        { type: ChallengeDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const deleteDevice: UserApiEndpoints['deleteDevice'] = async (request) => {
    const { response } = await patrickServiceClientAuth.deleteDevice(request)

    devtools.logGrpc({
      description: 'Delete Device',
      messages: [
        { type: DeleteDeviceRequest, key: 'request', message: request },
        { type: DeleteDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const provisionDevice: UserApiEndpoints['provisionDevice'] = async (request) => {
    const { response } = await patrickServiceClientAuth.provisionDevice(request)

    devtools.logGrpc({
      description: 'Provision Device',
      messages: [
        { type: ProvisionDeviceRequest, key: 'request', message: request },
        { type: ProvisionDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const registerDevice: UserApiEndpoints['registerDevice'] = async (request) => {
    const { response } = await patrickServiceClientAuth.registerDevice(request)

    devtools.logGrpc({
      description: 'Register Device',
      messages: [
        { type: RegisterDeviceRequest, key: 'request', message: request },
        { type: RegisterDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const updateDevice: UserApiEndpoints['updateDevice'] = async (request) => {
    const { response } = await patrickServiceClientAuth.updateDevice(request)

    devtools.logGrpc({
      description: 'Update Device',
      messages: [
        { type: UpdateDeviceRequest, key: 'request', message: request },
        { type: UpdateDeviceResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const account: UserApiEndpoints['account'] = async (request) => {
    const { response } = await patrickServiceClientAuth.account(request)

    devtools.logGrpc({
      description: 'Account',
      messages: [
        { type: AccountRequest, key: 'request', message: request },
        { type: AccountResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const requestPasswordReset: UserApiEndpoints['requestPasswordReset'] = async (request) => {
    const { response } = await patrickServiceClientAnon.requestPasswordReset(request)

    devtools.logGrpc({
      description: 'Request Password Reset',
      messages: [
        { type: RequestPasswordResetRequest, key: 'request', message: request },
        { type: RequestPasswordResetResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const resetPassword: UserApiEndpoints['resetPassword'] = async (request) => {
    const { response } = await patrickServiceClientAnon.resetPassword(request)

    devtools.logGrpc({
      description: 'Reset Password',
      messages: [
        { type: ResetPasswordRequest, key: 'request', message: request },
        { type: ResetPasswordResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const resendUserInvite: UserApiEndpoints['resendUserInvite'] = async (request) => {
    const { response } = await patrickServiceClientAuth.resendUserInvite(request)

    devtools.logGrpc({
      description: 'Resend User Invite',
      messages: [
        { type: ResendUserInviteRequest, key: 'request', message: request },
        { type: ResendUserInviteResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const validateInvitedUser: UserApiEndpoints['validateInvitedUser'] = async (request) => {
    const { response } = await patrickServiceClientAnon.validateInvitedUser(request)

    devtools.logGrpc({
      description: 'Validate Invited User',
      messages: [
        { type: ValidateInvitedUserRequest, key: 'request', message: request },
        { type: ValidateInvitedUserResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const listInternalUsers: UserApiEndpoints['listInternalUsers'] = async (request) => {
    const { response } = await patrickServiceClientAuth.listInternalUsers(request)

    devtools.logGrpc({
      description: 'List Internal Users',
      messages: [
        { type: ListInternalUsersRequest, key: 'request', message: request },
        { type: ListInternalUsersResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const getInternalUser: UserApiEndpoints['getInternalUser'] = async (request) => {
    const { response } = await patrickServiceClientAuth.getInternalUser(request)

    devtools.logGrpc({
      description: 'Get Internal User',
      messages: [
        { type: GetInternalUserRequest, key: 'request', message: request },
        { type: GetInternalUserResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const createInternalUser: UserApiEndpoints['createInternalUser'] = async (request) => {
    const { response } = await patrickServiceClientAuth.createInternalUser(request)

    devtools.logGrpc({
      description: 'Create Internal User',
      messages: [
        { type: CreateInternalUserRequest, key: 'request', message: request },
        { type: CreateInternalUserResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const updateInternalUser: UserApiEndpoints['updateInternalUser'] = async (request) => {
    const { response } = await patrickServiceClientAuth.updateInternalUser(request)

    devtools.logGrpc({
      description: 'Update Internal User',
      messages: [
        { type: UpdateInternalUserRequest, key: 'request', message: request },
        { type: UpdateInternalUserResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const deleteInternalUser: UserApiEndpoints['deleteInternalUser'] = async (request) => {
    const { response } = await patrickServiceClientAuth.deleteInternalUser(request)

    devtools.logGrpc({
      description: 'Delete Internal User',
      messages: [
        { type: DeleteInternalUserRequest, key: 'request', message: request },
        { type: DeleteInternalUserResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const listUsers: UserApiEndpoints['listUsers'] = async (request) => {
    const { response } = await patrickServiceClientAuth.listUsers(request)

    devtools.logGrpc({
      description: 'List Users',
      messages: [
        { type: ListUsersRequest, key: 'request', message: request },
        { type: ListUsersResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const getDevices: UserApiEndpoints['getDevices'] = async (request) => {
    const { response } = await patrickServiceClientAuth.getDevices(request)

    devtools.logGrpc({
      description: 'Get Devices',
      messages: [
        { type: GetDevicesRequest, key: 'request', message: request },
        { type: GetDevicesResponse, key: 'response', message: response },
      ],
    })

    return response
  }

  const endpoints: UserApiEndpoints = {
    validateUser,
    login,
    setupAccount,
    logout,
    updateAccount,
    changePassword,
    challengeDevice,
    deleteDevice,
    provisionDevice,
    registerDevice,
    updateDevice,
    account,
    requestPasswordReset,
    resetPassword,
    resendUserInvite,
    validateInvitedUser,
    listInternalUsers,
    getInternalUser,
    createInternalUser,
    updateInternalUser,
    deleteInternalUser,
    listUsers,
    getDevices,
  }
  return {
    django: endpoints,
    grpc: endpoints,
  }
}
