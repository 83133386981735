interface MatchingIdObject {
  id?: number
  userId?: number
  bingoId?: string
  ulid?: string
}

/**
 * Returns true if both objects either have a matching `id`, `userId`, `bingoId` or `ulid` field.
 * Missing ids are considered not matching.
 *
 * Useful for comparing objects that could be using any id.
 */
export function hasMatchingId (a: MatchingIdObject | null, b: MatchingIdObject | null): boolean {
  if (!a || !b) return false
  if (a.id && b.id && a.id === b.id) return true
  if (a.userId && b.userId && a.userId === b.userId) return true
  if (a.bingoId && b.bingoId && a.bingoId === b.bingoId) return true
  if (a.ulid && b.ulid && a.ulid === b.ulid) return true
  return false
}
