import { GrpcStatusCode } from '@protobuf-ts/grpcweb-transport'
import { RpcError } from '@protobuf-ts/runtime-rpc'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'
import { useFormEditStore } from '@/stores/form/edit'
import { useLoginStore } from '@/stores/login'
import { useNotificationStore } from '@/stores/notification'
import { useOnboardingStore } from '@/stores/onboarding'
import { useSettingsStore } from '@/stores/settings'
import { useSignatureStore } from '@/stores/signature'

import routeNames from './routeNames'
import { extractApiError } from '@/utils/api'

import type { NavigationGuard, RouteLocationNormalizedGeneric } from 'vue-router'

function routeRequiresAuth (to: RouteLocationNormalizedGeneric): boolean {
  return to.matched.some((route) => route.meta.requiresAuth)
}

/* Global Guards (in order of priority) */

/**
 * Ensures the user is authenticated and has a valid token if the route requires it.
 * Will log out the user and redirect to login if the token is invalid or expired.
 */
export const authenticationGuard: NavigationGuard = async (to, _from, next) => {
  if (!routeRequiresAuth(to)) return next()

  const apiStore = useApiStore()
  const loginStore = useLoginStore()
  if (apiStore.isLoggedIn) {
    return next()
  }
  // eslint-disable-next-line no-console
  console.log('Not logged in, redirecting to Login')
  const authenticationStore = useAuthenticationStore()
  authenticationStore.$reset()
  apiStore.logout()
  loginStore.targetPath = to.fullPath
  next({ name: routeNames.LOGIN })
}

/**
 * Ensures the "program" query param is always set on authenticated routes.
 * Will also switch to the program in the query if different.
 */
export const programQueryGuard: NavigationGuard = async (to, _from, next) => {
  const { query } = to
  const programSlug = query.program
  const authenticationStore = useAuthenticationStore()
  const requiresAuth = routeRequiresAuth(to)

  // Query param exists, ensure correct program has been selected
  if (programSlug) {
    const { programs, userId } = authenticationStore
    const foundProgram = programs.find((p) => p.slug === programSlug)

    if (foundProgram) {
      authenticationStore.programId = foundProgram.id!
      return next()
    } else {
      const notificationStore = useNotificationStore()
      console.error(`User ${userId} attempted to access program ${programSlug} but no subscription was found.`)
      notificationStore.setDefaultSnackbar({
        type: 'error',
        msg: 'You are not a member of the requested program.',
      })
      return next({ path: routeNames.HOME })
    }
  } else if (requiresAuth) {
    if (authenticationStore.currentProgram) {
      return next({ ...to, query: { ...query, program: String(authenticationStore.currentProgram!.slug) } })
    } else {
      const defaultProgram = authenticationStore.programs.find((p) => p.defaultProgram) ?? authenticationStore.programs[0]
      if (defaultProgram) {
        return next({ ...to, query: { ...query, program: String(defaultProgram.slug) } })
      } else {
        console.error('No currently selected program to include in querystring.')
        return next()
      }
    }
  }

  next()
}

/**
 * Will load the program settings on authenticated routes before continuing.
 * Redirects to login if token renewal fails.
 */
export const programSettingsGuard: NavigationGuard = async (to, _from, next) => {
  if (!routeRequiresAuth(to)) return next()

  try {
    const settingsStore = useSettingsStore()
    await settingsStore.load()
  } catch (err) {
    if (err instanceof RpcError && err.code === GrpcStatusCode[GrpcStatusCode.UNAUTHENTICATED]) {
      // eslint-disable-next-line no-console
      console.log('Token Renewal Failed, redirecting to Login')
      const authenticationStore = useAuthenticationStore()
      const apiStore = useApiStore()
      authenticationStore.$reset()
      apiStore.logout()
      return next({ name: routeNames.LOGIN })
    }
    const notificationStore = useNotificationStore()
    console.error(err)
    notificationStore.confirm({
      title: 'Program Load Error',
      body: 'This program could not be loaded. Please try reloading the page. If this error persists please contact support.',
      persistent: true,
      onlyConfirm: true,
    })
  }
  next()
}

/* Per-Route Guards */
export const registrationGuard: NavigationGuard = async (to, _from, next) => {
  const { name, query } = to
  const onboardingStore = useOnboardingStore()

  if (name === routeNames.REGISTER) {
    const { token, email } = query
    if (!token || !email) return next({ name: routeNames.LOGIN })
    try {
      await onboardingStore.loadUser({
        token: token as string,
        email: email as string,
      })
      next()
    } catch (err) {
      console.error('User unable to register', email, token, err)
      const notificationStore = useNotificationStore()
      notificationStore.setDefaultSnackbar({
        type: 'error',
        msg: extractApiError(err, 'An unknown error occurred.'),
      })
      next({ name: routeNames.LOGIN })
    }
  } else {
    if (!onboardingStore.loaded) return next({ name: routeNames.LOGIN })
    next()
  }
}

export const accountGuard: NavigationGuard = async (_to, _from, next) => {
  const authenticationStore = useAuthenticationStore()
  if (!authenticationStore.loaded) {
    try {
      await authenticationStore.load()
      next()
    } catch {
      next({ name: routeNames.HOME })
    }
  } else {
    next()
  }
}

export const superuserGuard: NavigationGuard = (_to, _from, next) => {
  const authenticationStore = useAuthenticationStore()
  const notificationStore = useNotificationStore()
  if (authenticationStore.userRole !== 'SUPERUSER') {
    notificationStore.setDefaultSnackbar({
      type: 'error',
      msg: 'You do not have permission to access this page.',
    })
    next({ name: routeNames.HOME })
  } else {
    next()
  }
}

export const signatureGuard: NavigationGuard = async (to, _from, next) => {
  const signatureStore = useSignatureStore()
  const { name, query } = to
  if (name === routeNames.SIGNATURE_DETAILS) {
    const { luid, program } = query
    if (!luid || !program) return next({ name: routeNames.LOGIN })
    signatureStore.program = program as string
    const res = await signatureStore.fetchAttachment(luid as string)
    // @ts-expect-error(external): This is probably a typo, should be looked at
    if (!res || res.signature_status === 'SIGNED') {
      return next({ name: routeNames.SIGNATURE_EXPIRED })
    } else {
      return next()
    }
  } else {
    if (!signatureStore.hasParams) return next({ name: routeNames.LOGIN })
  }
  next()
}

export const applicationStartGuard: NavigationGuard = async (_to, _from, next) => {
  const formEditStore = useFormEditStore()
  await formEditStore.loadData()
  next()
}

export const loggedInGuard: NavigationGuard = (_to, _from, next) => {
  const apiStore = useApiStore()
  if (apiStore.isLoggedIn) {
    return next({ name: routeNames.HOME })
  }
  return next()
}
