import { defineStore } from 'pinia'

import { useAuthenticationStore } from '@/stores/authentication'

import Payload from '@/lib/Payload'
import { handleChunkLoadError, hotReloadStore } from '@/utils/build'

import type { FormCoverage } from '@policyfly/schema/types/shared/formSchema'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type { ProgramSlug } from 'types/program'
import type { IssueFormSource, IssueSchema, IssueSection } from 'types/schema/issue'
import type { FormSchema } from 'types/schema/shared/formSchema'

export const useFormIssueStore = defineStore('formIssue', {
  state: () => ({
    schema: null as FormSchema<IssueFormSource, FormCoverage, IssueSection> | null,
    sectionsSlug: '' as ProgramSlug | '',
    responses: [] as APIPayloadResponse[],
  }),

  // TS refuses to infer the `state` argument in getters as IssueSection is so complex
  // instead we use `this` and provide a return type
  getters: {
    issuePayload (): Payload | null {
      if (!this.responses || !this.responses.length) return null
      return Payload.fromResponses(this.responses)
    },
  },

  actions: {
    async loadSections (): Promise<IssueSection<FormCoverage>[] | undefined> {
      const authenticationStore = useAuthenticationStore()
      const slug = authenticationStore.slug
      if (this.schema && this.sectionsSlug === slug) return Promise.resolve(this.schema.sections)
      this.sectionsSlug = ''
      try {
        const file = await import(`../../assets/programs/${slug}/issue.ts`)
        const schema = (file.default || file) as IssueSchema
        this.schema = schema
        this.sectionsSlug = slug
        return schema.sections
      } catch (err) {
        handleChunkLoadError(err)
      }
    },
  },
})

hotReloadStore(useFormIssueStore)
