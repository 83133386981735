import { PolicyState, Quote } from '@policyfly/protobuf'
import { isObject } from '@policyfly/utils/type'
import { normalizeFieldInfo, RepeatType, ScalarType } from '@protobuf-ts/runtime'
import { isReadonly, reactive, toRaw } from 'vue'

import { WORKING_COPIES_KEY } from '@/constants'
import { devtools } from '@/plugins/devtools/api'

import type { ReadonlyPolicyState } from '@/stores/protobuf'
import type { AnyObject, TSFixMe } from '@policyfly/types/common'
import type { FieldInfo } from '@protobuf-ts/runtime'

/**
 * Returns true if the passed {@link FieldInfo} is an array-type field.
 */
export function isArrayFieldInfo (fieldInfo: Partial<Pick<FieldInfo, 'repeat'>>): boolean {
  return fieldInfo.repeat === RepeatType.PACKED || fieldInfo.repeat === RepeatType.UNPACKED
}

/**
 * The {@link FieldInfo} for the base {@link PolicyState} message.
 */
export const PolicyStateFieldInfo = normalizeFieldInfo({
  no: 1,
  name: 'policyfly.PolicyState',
  kind: 'message',
  T: () => PolicyState,
})

/**
 * The {@link FieldInfo} for the base {@link Quote} message.
 */
export const QuoteFieldInfo = normalizeFieldInfo({
  no: 1,
  name: 'policyfly.Quote',
  kind: 'message',
  T: () => Quote,
})

export interface DrilledFieldInfo {
  /**
   * The parent value of the key.
   * The value you want to read/write will be the `key` in this value.
   */
  value: AnyObject | AnyObject[]
  /**
   * The key to the field, can be accessed using value[key].
   */
  key: string
  /**
   * Field information about the field stored under value[key].
   */
  fieldInfo: FieldInfo
  /**
   * Includes various versions of the path used to access the field.
   */
  paths: {
    /**
     * The fully qualified path to this field, replacing special syntaxes such as `(type=)`.
     */
    resolved: string
    /**
     * The path as it would appear within diff data, using uuid selectors instead of index selectors.
     */
    diff: string
  }
  /**
   * Extra information used to resolve the path based on external factors.
   */
  meta: {
    /**
     * The path of the currently selected quote.
     */
    selectedQuote: string
  }
}

export const PROXIED_OBJECT_KEY = Symbol('proxiedObject')
type CachedObject<T extends object> = T & { [PROXIED_OBJECT_KEY]: undefined }
interface CacheInfo {
  cache: PolicyStateCache
  pathParts: string[]
}
function isObjectToProxy (v: unknown): v is object {
  return !!v && typeof v === 'object' && !(v instanceof Uint8Array) && !(PROXIED_OBJECT_KEY in v)
}
function proxyObject <T extends object> (obj: T, cacheInfo: CacheInfo): CachedObject<T> {
  for (const [k, v] of Object.entries(obj)) {
    const newCacheInfo: CacheInfo = { ...cacheInfo, pathParts: [...cacheInfo.pathParts, k] }
    if (isObjectToProxy(v)) {
      obj[k as keyof T] = proxyObject(v, newCacheInfo) as TSFixMe
    }
  }
  return new Proxy(obj, {
    set (target, path, value, receiver) {
      if (typeof path === 'string') {
        let objectCachePath = ''
        const pathParts = [...cacheInfo.pathParts, path]
        if (isObjectToProxy(value)) {
          value = proxyObject(value, { ...cacheInfo, pathParts })
          // clear this object and everything nested
          objectCachePath = pathParts.join('.')
        } else {
          // clear only things that may be nested
          // accounts for setting an object to null
          objectCachePath = `${pathParts.join('.')}.`
        }
        if (objectCachePath) {
          for (const [k, v] of Object.entries(cacheInfo.cache)) {
            if (v.paths.resolved.startsWith(objectCachePath)) {
              delete cacheInfo.cache[k]
            }
          }
        }

        // clear all items in an array in case index has shifted
        if (Array.isArray(obj) && !path.startsWith(WORKING_COPIES_KEY)) {
          const arrayCachePaths = cacheInfo.pathParts.join('.')
          const workingCopiesPath = `${arrayCachePaths}.${WORKING_COPIES_KEY}`
          for (const [k, v] of Object.entries(cacheInfo.cache)) {
            if (v.paths.resolved.startsWith(arrayCachePaths) && !v.paths.resolved.startsWith(workingCopiesPath)) {
              delete cacheInfo.cache[k]
            }
          }
        }
      }
      return Reflect.set(target, path, value, receiver)
    },
    has (target, path) {
      if (path === PROXIED_OBJECT_KEY) return true
      return Reflect.has(target, path)
    },
  }) as CachedObject<T>
}

export const CACHE_KEY = Symbol('cacheKey')
export type PolicyStateCache = Record<string, DrilledFieldInfo>
export type CachedPolicyState<T extends PolicyState | ReadonlyPolicyState> = T & { [CACHE_KEY]: PolicyStateCache }
/**
 * Returns a proxy for the provided {@link PolicyState} that has an internal cache.
 * - A `readonly` PolicyState will be proxied to add a cache on top of the existing readonly proxy
 * - A non-`readonly` PolicyState (`reactive` or not) will add a cache, deeply proxy the raw object track any changes that would invalidate the cache,
 *  make that resultant proxy reactive and return it
 *
 * Mainly for use with {@link cachedDrillFieldInfo}.
 */
export function cachedPolicyState<T extends PolicyState | ReadonlyPolicyState> (policyState: T): CachedPolicyState<T> {
  const cache: PolicyStateCache = reactive({})
  const wasReadonly = isReadonly(policyState)
  const objectToProxy = wasReadonly ? policyState : toRaw(policyState)
  const baseProxy = new Proxy(objectToProxy, {
    get (target, path, receiver) {
      if (path === CACHE_KEY) return cache
      return Reflect.get(target, path, receiver)
    },
    has (target, path) {
      if (path === CACHE_KEY) return true
      return Reflect.has(target, path)
    },
  }) as CachedPolicyState<T>
  if (wasReadonly) return baseProxy
  return reactive(proxyObject(baseProxy, { cache, pathParts: [] })) as CachedPolicyState<T>
}

type PossiblyCachedPolicyState = PolicyState | ReadonlyPolicyState | CachedPolicyState<PolicyState> | CachedPolicyState<ReadonlyPolicyState>
/**
 * If provided a proxied PolicyState will use the cache if possible when drilling field info.
 * Can also be passed a un-proxied PolicyState and the cache will be ignored.
 */
export function cachedDrillFieldInfo (
  create: boolean,
  path: string,
  policyState: PossiblyCachedPolicyState,
): DrilledFieldInfo | null {
  const cache = CACHE_KEY in policyState ? policyState[CACHE_KEY] : null
  if (cache && path in cache) {
    return cache[path]
  }
  const result = drillFieldInfo(
    create,
    PolicyStateFieldInfo,
    path,
    policyState,
  )
  if (result && cache) {
    cache[path] = result
    if (result.paths.resolved !== path) cache[result.paths.resolved] = result
  }
  return result
}

/**
 * Starting with a valid protobuf struct value, will drill down to the specified path and return the {@link DrilledFieldInfo} for it.
 *
 * @param create Whether to create missing parent values while drilling, if `false` will return `null` as soon as a value does not exist
 * @param fieldInfo The field information of the starting value being passed in
 * @param path The dot notation path to drill to, you can select items within arrays using the syntax `premiums.(type=3).amount`
 * @param value The starting value
 * @param paths see {@link DrilledFieldInfo.paths paths}
 * @param meta see {@link DrilledFieldInfo.meta meta}
 */
export function drillFieldInfo (
  create: boolean,
  fieldInfo: FieldInfo,
  path: string,
  value: AnyObject | AnyObject[],
  paths: DrilledFieldInfo['paths'] = { resolved: '', diff: '' },
  meta: DrilledFieldInfo['meta'] = { selectedQuote: '' },
): DrilledFieldInfo | null {
  let [key, ...rest] = path.split('.')
  if (!key) return null
  if (fieldInfo.kind !== 'message') return null

  // find info for next field
  let nextFieldInfo: FieldInfo | null = null
  const message = fieldInfo.T()

  if (message.typeName === 'policyfly.Address' && key === 'address') key = 'address1'

  let diffKey = key
  if (isArrayFieldInfo(fieldInfo)) {
    if (key === WORKING_COPIES_KEY) {
      nextFieldInfo = fieldInfo
    } else {
      nextFieldInfo = { ...fieldInfo, repeat: RepeatType.NO }
      if (Array.isArray(value)) {
        // check for `(type=3)` syntax
        const matches = /^\((.*)=(.*)\)$/.exec(key)
        if (matches) {
          const [, k, v] = matches
          const index = value.findIndex((item) => String(item[k]) === v)
          if (index === -1) return null
          key = String(index)
          diffKey = String(index)
        }
        const item = value[key as unknown as number]
        if (isObject(item) && 'uuid4' in item && item.uuid4) {
          diffKey = item.uuid4
        }
      }
    }
  } else {
    // look for selected quote
    if (!Array.isArray(value) && value.quotes &&
      value.quotes.selected && Array.isArray(value.quotes.selected) && value.quotes.selected.length) {
      meta.selectedQuote = `quotes.quotes.(uuid4=${value.quotes.selected[0]})`
    }
    // check for `(selectedQuote)` syntax
    if (key === '(selectedQuote)') {
      if (!meta.selectedQuote) {
        devtools.configWarning({
          type: 'data',
          title: 'Used a (selectedQuote) path, but no quote has been selected.',
        })
        return null
      }
      key = diffKey = meta.selectedQuote.split('.')[0]
      rest = meta.selectedQuote.split('.').slice(1).concat(rest)
    }
    nextFieldInfo = message.fields.find((f) => f.localName === key) ?? null
  }
  if (!nextFieldInfo) return null

  const fullPath = paths.resolved ? `${paths.resolved}.${key}` : key
  const fullDiffPath = paths.diff ? `${paths.diff}.${diffKey}` : diffKey
  if (!rest.length) {
    return { value, key, fieldInfo: nextFieldInfo, paths: { resolved: fullPath, diff: fullDiffPath }, meta }
  }

  if (nextFieldInfo.kind !== 'message') {
    // non-message array check
    if (isArrayFieldInfo(nextFieldInfo) && rest.length === 1) {
      let nextValue: AnyObject | AnyObject[] | null | undefined = value[key as keyof typeof value]
      if (!nextValue) {
        if (create) value[key as keyof typeof value] = nextValue = []
        else return null
      }
      key = rest[0]
      return {
        value: nextValue,
        key,
        fieldInfo: { ...nextFieldInfo, repeat: RepeatType.NO },
        paths: { resolved: `${fullPath}.${key}`, diff: `${fullDiffPath}.${key}` },
        meta,
      }
    }

    return null
  }

  let nextValue: AnyObject | AnyObject[] | null | undefined = value[key as keyof typeof value]
  if (!nextValue && create) {
    nextValue = isArrayFieldInfo(nextFieldInfo) ? [] : nextFieldInfo.T().create()
    value[key as keyof typeof value] = nextValue
  }

  if (!nextValue) return null
  return drillFieldInfo(create, nextFieldInfo, rest.join('.'), nextValue, { resolved: fullPath, diff: fullDiffPath }, meta)
}

/**
 * A key that points to a unique area in a PolicyState where untyped temporary data can be written.
 *
 * This is useful for storing some temporary values that do not need to be persisted.
 */
export const SCRATCH_KEY = '$scratch'

function scratchFieldInfo (path: string, value: AnyObject, type: ScalarType): DrilledFieldInfo {
  return {
    key: path,
    value,
    fieldInfo: {
      no: 1,
      name: path,
      localName: path,
      jsonName: path,
      kind: 'scalar',
      T: type,
      repeat: RepeatType.NO,
      opt: true,
      oneof: undefined,
    },
    paths: {
      resolved: path,
      diff: path,
    },
    meta: {
      selectedQuote: '',
    },
  }
}

/**
 * Gets the value from the provided source in the reserved scratch area of data (see {@link SCRATCH_KEY}).
 *
 * @param path The path in the scratch that will be read from.
 * @param source The PolicyState to read from.
 */
export function getScratchFieldInfo (path: string, source: PolicyState | ReadonlyPolicyState): DrilledFieldInfo | null {
  if (!source[SCRATCH_KEY]) return null
  return scratchFieldInfo(path, source[SCRATCH_KEY], ScalarType.BOOL)
}

/**
 * Sets the value to the provided source in the reserved scratch area of data (see {@link SCRATCH_KEY}).
 *
 * @param path The path in the scratch that will be written to.
 * @param source The PolicyState to write to.
 * @param value The value to be written (used to ensure the type of value is consistent).
 */
export function setScratchFieldInfo (path: string, source: PolicyState, value: unknown): DrilledFieldInfo {
  if (!source[SCRATCH_KEY]) source[SCRATCH_KEY] = reactive({})
  const type = typeof value === 'boolean'
    ? ScalarType.BOOL
    : typeof value === 'string'
      ? ScalarType.STRING
      : ScalarType.FLOAT
  return scratchFieldInfo(path, source[SCRATCH_KEY], type)
}
