import {
  Agency,
  CoverHolder,
  Date as DateStruct,
  Decimal,
  Policy,
  PolicyState,
} from '@policyfly/protobuf'
import { Timestamp } from '@policyfly/protobuf/google/protobuf'
import {
  dateToString,
  decimalToString,
  stringToDate,
  stringToDecimal,
  timestampToString,
  stringToTimestamp,
  agencyOrCoverHolderToString,
  stringToAgencyOrCoverHolder,
} from '@policyfly/utils/protobuf'
import { addUUIDs, UUID_REGEX } from '@policyfly/utils/uuid'
import { defineStore } from 'pinia'
import { computed, readonly, ref } from 'vue'

import { useDiffStore } from '@/stores/diff'

import { WORKING_COPIES_KEY } from '@/constants'
import { devtools } from '@/plugins/devtools/api'
import { hotReloadStore } from '@/utils/build'
import {
  getScratchFieldInfo,
  setScratchFieldInfo,
  isArrayFieldInfo,
  SCRATCH_KEY,
  cachedPolicyState,
  convertScalar,
  drillFieldInfo,
  PolicyStateFieldInfo,
} from '@/utils/protobuf'

import type { Coverage, Quote } from '@policyfly/protobuf'
import type { InputDataProtobuf } from '@policyfly/schema/types/shared/inputData'
import type { AnyObject } from '@policyfly/types/common'
import type { DeepReadonly, ComputedRef, WritableComputedRef } from 'vue'

export type ReadonlyPolicyState = DeepReadonly<PolicyState>
export type ReadonlyCoverage = DeepReadonly<Coverage>
export type ReadonlyPolicy = DeepReadonly<Policy>
type ReadonlyQuote = DeepReadonly<Quote>

export interface ProtobufStore {
  /**
   * The currently loaded {@link PolicyState} for the active Application.
   * Is an empty state if no Application is loaded.
   * This is readonly and cannot be modified, see {@link policyStateWorkingCopy}.
   */
  policyState: WritableComputedRef<ReadonlyPolicyState>
  /**
   * An editable {@link PolicyState} that can be used for forms or other data entry.
   * These changes are not persisted until requested and can be discarded.
   *
   * **NOTE: This should not be sent directly to the GRPC services, instead use `getProcessedPolicyState`.**
   */
  policyStateWorkingCopy: WritableComputedRef<PolicyState | null>
  /**
   * The currently loaded {@link Policy} for the active Policy.
   * Is an empty state if no Application is loaded.
   * This is readonly and cannot be modified, see {@link policyStateWorkingCopy}.
   */
  policy: WritableComputedRef<ReadonlyPolicy>
  /**
   * Either the `policyStateWorkingCopy` or, if that doesn't exist, the `policyState`.
   */
  currentPolicyState: ComputedRef<ReadonlyPolicyState | PolicyState>
  /**
   * The currently selected quotes from the `currentPolicyState`.
   */
  selectedQuotes: ComputedRef<ReadonlyQuote[]>
  /**
   * Copies the `policyState` to `policyStateWorkingCopy` so it can be edited.
   */
  createWorkingCopy (): void
  /**
   * Updates the `policyStateWorkingCopy` with the provided PolicyState.
   * Ensures properties such as the scratch data are safely preserved.
   *
   * @param policyState The new PolicyState to update data from.
   * @param save If `true` will also save a readonly copy to the saved `policyState`. Default: `false`.
   */
  updateWorkingCopy (updatedState: PolicyState, save?: boolean): void
  /**
   * Gets a value from a PolicyState at the provided path.
   *
   * Changing the access affects where the data is retrieved from, see {@link InputDataProtobuf.access access}.
   */
  getPolicyState<T>(path: string, access?: InputDataProtobuf<string>['access']): T | undefined
  /**
   * Sets a value in the `policyStateWorkingCopy` at the provided path.
   * Warns if there is no working copy, or the value could not be set.
   */
  setPolicyState (path: string, value: unknown, access?: InputDataProtobuf<string>['access']): void
  /**
   * Clones and processes the `policyStateWorkingCopy` so that all fields are correctly filled
   * and ready to be sent to a GRPC service.
   */
  getProcessedPolicyState (): PolicyState
  /**
   * Processes the `policyStateWorkingCopy` and converts it to a `Blob`.
   */
  serializePolicyState (): Blob
  /**
   * Persists the `policyStateWorkingCopy` to the `policyState`.
   */
  savePolicyState (): void
  /**
   * Either loads the passed in PolicyState or saves the working copy.
   * Useful for loading the result of a "submit" endpoint.
   *
   * If neither of these are available will show a console error.
   */
  loadPolicyState (updatedState: PolicyState | undefined): void
  /**
   * Loads the current diff state from the {@link useDiffStore diffStore} and creates a working copy.
   * This mainly is used to add removed items from arrays so they can be displayed.
   */
  loadDiffWorkingCopy (): void
  /**
   * Resets to the initial state.
   */
  $reset (): void
}

export const useProtobufStore = defineStore('protobuf', (): ProtobufStore => {
  // TODO: Replace with `cachedDrillFieldInfo` when fixed to work with latest Vue, see PF-5901
  const getPolicyStateInfo = drillFieldInfo.bind(null, false, PolicyStateFieldInfo)
  const setPolicyStateInfo = drillFieldInfo.bind(null, true, PolicyStateFieldInfo)

  const policyStateInternal = ref<ReadonlyPolicyState>(cachedPolicyState(readonly(PolicyState.create())))
  const policyState: ProtobufStore['policyState'] = computed({
    get: (): ReadonlyPolicyState => policyStateInternal.value,
    set: (val: PolicyState | ReadonlyPolicyState): void => {
      policyStateInternal.value = cachedPolicyState(readonly(val))
    },
  })

  const policyStateWorkingCopyInternal = ref<PolicyState | null>(null)
  const policyStateWorkingCopy: ProtobufStore['policyStateWorkingCopy'] = computed({
    get: (): PolicyState | null => policyStateWorkingCopyInternal.value,
    set: (val: PolicyState | null): void => {
      policyStateWorkingCopyInternal.value = val && cachedPolicyState(val)
    },
  })

  const policyInternal = ref<ReadonlyPolicy>(readonly(Policy.create()))
  const policy: ProtobufStore['policy'] = computed({
    get: (): ReadonlyPolicy => policyInternal.value,
    set: (val: Policy | ReadonlyPolicy): void => {
      policyInternal.value = readonly(val)
    },
  })

  const currentPolicyState: ProtobufStore['currentPolicyState'] = computed(() => {
    return policyStateWorkingCopy.value ?? policyState.value
  })

  const selectedQuotes: ProtobufStore['selectedQuotes'] = computed(() => {
    const selectedUuids = currentPolicyState.value.quotes?.selected ?? []
    const firstQuote = currentPolicyState.value.quotes?.quotes[0]
    const quotes = selectedUuids.map((uuid) => currentPolicyState.value.quotes?.quotes.find((quote) => quote.uuid4 === uuid))
    return (!quotes.length && firstQuote ? [firstQuote] : quotes).map((quote) => readonly(quote!))
  })

  const createWorkingCopy: ProtobufStore['createWorkingCopy'] = () => {
    policyStateWorkingCopy.value = PolicyState.clone(policyState.value as PolicyState)
  }

  const updateWorkingCopy: ProtobufStore['updateWorkingCopy'] = (updatedState, save = false) => {
    if (!policyStateWorkingCopy.value) {
      console.warn('Trying to update working copy but it does not exist. Make sure `useProtobufCopy` has been run on this page.')
      return
    }
    const clone = PolicyState.clone(updatedState)
    clone[SCRATCH_KEY] = policyStateWorkingCopy.value[SCRATCH_KEY]
    policyStateWorkingCopy.value = clone
    if (save) {
      policyState.value = PolicyState.clone(updatedState)
    }
  }

  const getPolicyState: ProtobufStore['getPolicyState'] = (path, access = 'write') => {
    const sourceState = access === 'saved'
      ? policyState.value
      : currentPolicyState.value
    const info = access === 'scratch'
      ? getScratchFieldInfo(path, sourceState)
      : getPolicyStateInfo(path, sourceState)
    if (!info) return undefined
    if (!(info.key in info.value)) return undefined
    const readValue = info.value[info.key as keyof typeof info.value]
    switch (info.fieldInfo.kind) {
      case 'message': {
        const message = info.fieldInfo.T()
        switch (message.typeName) {
          case DateStruct.typeName: return dateToString(readValue as DateStruct)
          case Decimal.typeName: return decimalToString(readValue as Decimal)
          case Timestamp.typeName: return timestampToString(readValue as Timestamp)
          case Agency.typeName:
          case CoverHolder.typeName:
            return agencyOrCoverHolderToString(readValue as Agency | CoverHolder)
        }
      }
    }
    return readValue
  }

  const setPolicyState: ProtobufStore['setPolicyState'] = (path, value, access = 'write') => {
    if (!policyStateWorkingCopy.value) {
      console.warn('Trying to set value but no working copy exists. Make sure `useProtobufCopy` has been run on this page.')
      return
    }
    if (access === 'readonly') {
      console.warn('Trying to set value with readonly access.')
      return
    }
    const info = access === 'scratch'
      ? setScratchFieldInfo(path, policyStateWorkingCopy.value, value)
      : setPolicyStateInfo(path, policyStateWorkingCopy.value)
    if (!info) {
      devtools.configWarning({
        type: 'input',
        title: `Failed to set path ${path} to value ${value}`,
      })
      return
    }
    switch (info.fieldInfo.kind) {
      case 'message': {
        const message = info.fieldInfo.T()

        if (info.key === WORKING_COPIES_KEY) {
          // overwrite entire working copies
          info.value[info.key] = value
          return
        }

        if (isArrayFieldInfo(info.fieldInfo)) {
          if (!Array.isArray(value)) {
            devtools.configWarning({
              type: 'input',
              title: `Failed to set array path ${path} to a non-array value ${value}`,
            })
          } else {
            // overwrites entire array with value
            info.value[info.key as keyof typeof info.value] = value
          }
          return
        }

        let setValue: AnyObject | number | null = null
        switch (message.typeName) {
          case DateStruct.typeName: setValue = stringToDate(value as string | null); break
          case Decimal.typeName: setValue = stringToDecimal(value as string | null); break
          case Timestamp.typeName: setValue = stringToTimestamp(value as string | null); break
          case Agency.typeName:
          case CoverHolder.typeName: {
            setValue = stringToAgencyOrCoverHolder(value as string | null)
            break
          }
          default:
            if (value && typeof value === 'object') setValue = message.create(value as AnyObject)
            else if (value !== null) {
              devtools.configWarning({
                type: 'input',
                title: `Failed to set object path ${path} to a non-object value ${value}`,
              })
              return
            }
        }
        const messageKey = info.key as keyof typeof info.value
        const previousMessage = info.value[messageKey]
        if (previousMessage && typeof previousMessage === 'object' && setValue && typeof setValue === 'object') {
          message.mergePartial(previousMessage, setValue)
        } else {
          info.value[messageKey] = setValue
        }
        return
      }
      case 'scalar': {
        const scalarType = info.fieldInfo.T
        if (isArrayFieldInfo(info.fieldInfo)) {
          if (!Array.isArray(value)) {
            devtools.configWarning({
              type: 'input',
              title: `Failed to set array path ${path} to a non-array value ${value}`,
            })
          } else {
            // overwrites entire array with value
            info.value[info.key as keyof typeof info.value] = value.map((v) => convertScalar(v, scalarType))
          }
          return
        }
        info.value[info.key as keyof typeof info.value] = convertScalar(value, scalarType)
        break
      }
      default:
        info.value[info.key as keyof typeof info.value] = value
    }
  }

  const getProcessedPolicyState: ProtobufStore['getProcessedPolicyState'] = () => {
    if (!policyStateWorkingCopy.value) throw new Error('No working PolicyState available')
    const clone = PolicyState.clone(policyStateWorkingCopy.value)
    return addUUIDs(clone)
  }

  const serializePolicyState: ProtobufStore['serializePolicyState'] = () => {
    return new Blob([PolicyState.toBinary(getProcessedPolicyState())])
  }

  const savePolicyState: ProtobufStore['savePolicyState'] = () => {
    policyState.value = getProcessedPolicyState()
  }

  const loadPolicyState: ProtobufStore['loadPolicyState'] = (updatedState) => {
    if (updatedState) {
      policyState.value = updatedState
    } else if (policyStateWorkingCopy.value) {
      savePolicyState()
    } else {
      console.error('No PolicyState available to be loaded')
    }
  }

  const diffStore = useDiffStore()
  const loadDiffWorkingCopy: ProtobufStore['loadDiffWorkingCopy'] = () => {
    createWorkingCopy()
    const removed = diffStore.diffData?.removed ?? []
    // create any missing array items
    for (const item of removed) {
      const parts = item.k.split('.')
      const prefixParts = []
      const suffixParts = []
      let uuid = ''
      for (const part of parts) {
        if (UUID_REGEX.test(part)) {
          uuid = part
          continue
        }
        if (uuid) suffixParts.push(part)
        else prefixParts.push(part)
      }
      if (uuid) {
        const arrK = prefixParts.join('.')
        const arr = getPolicyState<AnyObject[]>(arrK)
        if (!arr) setPolicyState(arrK, [])
        if (!arr || !arr.some((v) => v.uuid4 === uuid)) {
          const len = arr?.length ?? 0
          setPolicyState([arrK, len, 'uuid4'].join('.'), uuid)
        }
      }
    }
    // set all removed values
    for (const item of removed) {
      const k = item.k.split('.')
        .map((v) => UUID_REGEX.test(v) ? `(uuid4=${v})` : v)
        .join('.')
      setPolicyState(k, item.v)
    }
  }

  const $reset: ProtobufStore['$reset'] = () => {
    policyState.value = PolicyState.create()
    policyStateWorkingCopy.value = null
    policy.value = Policy.create()
  }

  return {
    policyState,
    policyStateWorkingCopy,
    policy,
    currentPolicyState,
    selectedQuotes,
    createWorkingCopy,
    updateWorkingCopy,
    getPolicyState,
    setPolicyState,
    getProcessedPolicyState,
    serializePolicyState,
    savePolicyState,
    loadPolicyState,
    loadDiffWorkingCopy,
    $reset,
  }
})

hotReloadStore(useProtobufStore)
