import { User as CoreUser } from '@policyfly/protobuf'
import { MultipartFormFile, UpdateAccountRequest } from '@policyfly/protobuf/patrick'
import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'

import { hotReloadStore } from '@/utils/build'
import { hasMatchingId } from '@/utils/compare'

import type { Program, UserAgency, UserProgram } from '@policyfly/protobuf/patrick'
import type { RoleNameIncSuperuser } from '@policyfly/types/user'
import type { ProgramSlug } from 'types/program'

export interface AuthenticationState {
  /**
   * The logged in user's django id.
   */
  userId: number | null
  /**
   * The logged in user's bingo id.
   */
  bingoId: string | null
  /**
   * The logged in user's avatar, if available.
   */
  avatar: string | null
  /**
   * The logged in user's first name.
   */
  firstName: string
  /**
   * The logged in user's last name.
   */
  lastName: string
  /**
   * The logged in user's email.
   */
  email: string
  /**
   * The programs the logged in user has access to.
   */
  programs: UserProgram[]
  /**
   * @todo Make this support bingo ids.
   *
   * The currently active program's django id.
   */
  programId: number
  /**
   * The agencies the logged in user belongs to.
   */
  agencies: UserAgency[]
}

export const useAuthenticationStore = defineStore('authentication', {
  state: (): AuthenticationState => ({
    userId: null,
    bingoId: null,
    avatar: null,
    firstName: '',
    lastName: '',
    email: '',
    programs: [],
    programId: 0,
    agencies: [],
  }),

  getters: {
    loaded (): boolean {
      return !!this.firstName
    },
    currentProgram (): UserProgram | null {
      return this.programs.find((p) => +p.id! === +this.programId) ?? null
    },
    /**
     * Turns the current user's details into a Protobuf {@link CoreUser User}.
     */
    coreUser (): CoreUser {
      return CoreUser.create({
        id: this.userId ?? undefined,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        ulid: this.bingoId ?? undefined,
      })
    },
    fullName (): string {
      return `${this.firstName} ${this.lastName}`
    },
    /**
     * Returns all agencies that are relevant to the currently active program.
     */
    currentAgencies (): UserAgency[] {
      if (!this.currentProgram) return []
      const currentProgram = this.currentProgram
      return this.agencies.filter((agency) => {
        return agency.programs.some((program) => {
          return hasMatchingId(currentProgram, program)
        })
      })
    },
    userRole (): RoleNameIncSuperuser | null {
      return this.currentProgram ? this.currentProgram.role as RoleNameIncSuperuser : null
    },
    isProgramAdmin (): boolean {
      return !!this.userRole && ['PROGRAM_ADMIN', 'SUPERUSER'].includes(this.userRole)
    },
    isProgmin (): boolean {
      return !!this.userRole && ['TECHNICIAN', 'UNDERWRITER', 'PROGRAM_ADMIN', 'SUPERUSER'].includes(this.userRole)
    },
    isInternalRole (): boolean {
      return !!this.userRole && ['TECHNICIAN', 'UNDERWRITER', 'QUALITY_CONTROLLER', 'PROGRAM_ADMIN', 'SUPERUSER'].includes(this.userRole)
    },
    isUnderwriterOrProgmin (): boolean {
      return !!this.userRole && ['UNDERWRITER', 'PROGRAM_ADMIN', 'SUPERUSER'].includes(this.userRole)
    },
    slug (): ProgramSlug | '' {
      return this.currentProgram ? this.currentProgram.slug as ProgramSlug : ''
    },
  },

  actions: {
    async load (): Promise<void> {
      const apiStore = useApiStore()
      const response = await apiStore.user.account({
        bingoId: this.bingoId ?? undefined,
      })
      this.userId = response.id ?? null
      this.avatar = response.avatar ?? ''
      this.firstName = response.firstName
      this.lastName = response.lastName
      this.email = response.email
      this.programs = response.programs
      this.agencies = response.agencies
    },
    async editDetails (
      data: Pick<UpdateAccountRequest, 'firstName' | 'lastName' | 'email'> & { photo: File | null },
    ): Promise<void> {
      const apiStore = useApiStore()
      const request = UpdateAccountRequest.create({
        id: this.userId ?? undefined,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        bingoId: this.bingoId ?? undefined,
      })
      if (data.photo) {
        request.avatar = MultipartFormFile.create({
          name: data.photo.name,
          mimeType: data.photo.type,
          content: new Uint8Array(await data.photo.arrayBuffer()),
        })
      }
      const res = await apiStore.user.updateAccount(request)
      if (data.photo && res.avatarUrl) this.avatar = res.avatarUrl
      this.firstName = res.firstName
      this.lastName = res.lastName
      this.email = res.email
    },
    /**
     * Returns true if a default program was assigned, false if no programs exist
     */
    checkDefaultProgram (): boolean {
      if (!this.programs.length) return false
      // switch to the default or first program
      const defaultProgram = this.programs.find((m) => m.defaultProgram) || this.programs[0]
      this.programId = defaultProgram.id!
      return true
    },
    /**
     * Returns true if the user has access to the given program id
     */
    hasProgramAccess (program: Pick<Program, 'id' | 'bingoId'>): boolean {
      return this.programs.some((p) => {
        return hasMatchingId(p, program)
      })
    },
    /**
     * Attempts to log the user out of the application and invalidate the session with the API.
     * Removes all tokens and authentication data from stores.
     */
    async logout (): Promise<void> {
      try {
        const apiStore = useApiStore()
        await apiStore.user.logout()
      } catch (err) {
        console.error(`Error invalidating session with API. Clearing local data. ${err}`)
      } finally {
        this.$reset()
      }
    },
  },
})

hotReloadStore(useAuthenticationStore)
