import {
  ApplicationServiceClient,
  DiffResponse,
  PolicyStateDiffServiceClient,
  PolicyState,
  Program,
  RetrieveByIdRequest,
  TransactionType,
  QuoteDecision,
  GenericStatus,
  GenericEventType,
  User as CoreUser,
  Request,
  EventIDType,
  Kind,
  Policy,
  RunWorkflowRequest,
  RunWorkflowResponse,
} from '@policyfly/protobuf'
import {
  PatrickServiceClient,
  ReadPolicyStateRequest,
} from '@policyfly/protobuf/patrick'
import { FORM_INITIAL_FLAG_PATH } from '@policyfly/utils/constants'
import { timestampToString } from '@policyfly/utils/protobuf'
import { toCamelCase } from '@policyfly/utils/string'
import * as Sentry from '@sentry/browser'
import { AxiosError } from 'axios'
import orderBy from 'lodash-es/orderBy'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'
import { useFormEditStore } from '@/stores/form/edit'
import { useProtobufStore } from '@/stores/protobuf'
import { useSettingsStore } from '@/stores/settings'

import { api } from '@/api'
import { devtools } from '@/plugins/devtools/api'
import { objectToFormData } from '@/utils/api'
import {
  applicationStatusToGenericStatus,
  extractAnyWrapper,
  patrickUserToCoreUser,
  policyStateToApplication,
} from '@/utils/protobuf'

import type { UseRaterReturnValue } from '@/composables/rater'
import type Payload from '@/lib/Payload'
import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'
import type { DiffResult } from '@policyfly/protobuf'
import type { User, UserProgram } from '@policyfly/protobuf/patrick'
import type { ApplicationStatus } from '@policyfly/types/application'
import type { PrimitiveValue } from '@policyfly/types/common'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type { AxiosResponseHeaders } from 'axios'
import type {
  APIApplication,
  DiffData,
  ApplicationKind,
  APIApplicationRaw,
} from 'types/application'
import type { APIPolicy } from 'types/policy'
import type { EditFormType } from 'types/schema/edit'
import type { paths } from 'types/swagger'

/**
 * A callback function that handles the setting of state and legacy rating operation after data is saved.
 * This is only called during the submission of a django application.
 */
export type RateOnSubmit = (json: APIApplication | undefined, responses: APIPayloadResponse[]) => ReturnType<UseRaterReturnValue['rate']>
export interface SubmitResponse {
  policyState?: PolicyState
  policy?: APIPolicy
  json: APIApplication | APIApplicationRaw
  status: GenericStatus
}
export interface SubmitResponseProtobuf {
  policyState: PolicyState
}
export interface ApplicationApiEndpoints {
  /**
   * Approves an Endorsement Application
   */
  approve: (id: number) => Promise<SubmitResponse>
  /**
   * Archives an Application
   */
  archive: (id: number) => Promise<void>
  /**
   * Set which user an Application is assigned to
   */
  assign: (id: number, assignee: User | false) => Promise<SubmitResponse>
  /**
   * Authorise an Application and transition to active Policy
   */
  authorise: (id: number) => Promise<SubmitResponse>
  /**
   * Send an Application back to Reviewing status.
   */
  backToReviewing: (id: number) => Promise<SubmitResponse>
  /**
   * Binds a quote
   */
  bindQuote: () => Promise<SubmitResponse>
  /**
   * Cancels an Application
   */
  cancel: (id: number) => Promise<SubmitResponse>
  /**
   * Does a comparison (aka "diff") of 2 Applications based on their id.
   *
   * @returns A summary of changes between the 2.
   */
  compare: (baseId: number, targetId: number) => Promise<DiffData>
  /**
   * Creates a new Application
   */
  create: (currentProgram: UserProgram, payload: Payload) => Promise<{
    policyState?: PolicyState
    json: SubmitResponse['json']
  }>
  /**
   * Deletes an Application
   */
  delete: (id: number) => Promise<void>
  /**
   * Declines an Application
   */
  decline: (id: number) => Promise<SubmitResponse>
  /**
   * Transitions an Application back to draft
   */
  draft: (id: number) => Promise<SubmitResponse>
  /**
   * Transitions an Application to an active Policy
   */
  finalise: (id: number) => Promise<SubmitResponse>
  /**
   * Returns the last modified timestamp of the application with the provided id.
   */
  freshness: (id: number) => Promise<string>
  /**
   * Generates a new quote document.
   * Intended for use on the Quote Review page.
   */
  generateQuoteDoc: (id: number) => Promise<SubmitResponse>
  /**
   * Issues a Policy from an Application
   */
  issue: (id: number, body?: APIPayloadResponse[], fuzzy?: boolean) => Promise<SubmitResponse>
  /**
   * Apply issuance data to an Application without issuing the Policy
   */
  issuanceData: (id: number, body: APIPayloadResponse[], fuzzy?: boolean) => Promise<SubmitResponse>
  /**
   * Override action for an Application
   */
  lock: (id: number) => Promise<{
    policyState?: PolicyState
    json: SubmitResponse['json']
  }>
  /**
   * Mark a Quote as lost and discard the Application
   */
  lost: (id: number) => Promise<SubmitResponse>
  /**
   * Skip signing step and advance Application to pending issue
   */
  pendingIssue: (id: number, payload?: Payload, binary?: Blob) => Promise<SubmitResponse>
  /**
   * Submit the quality control response for an application
   */
  qualityControl: (id: number, action: string) => Promise<SubmitResponse>
  /**
   * Transition an Application back to Quality Control
   */
  qualityRecheck: (id: number) => Promise<SubmitResponse>
  /**
   * Submits or saves a Quote for an Application
   */
  quote: (id: number, body: FormData, kind: Kind, fuzzy?: boolean) => Promise<SubmitResponse>
  /**
   * Requests latest rating information based on the provided input.
   *
   * **NOTE: This only supports protobuf programs.**
   */
  rate: (txn: TransactionType, abortController: AbortController) => Promise<SubmitResponseProtobuf>
  /**
   * Finds & returns data about an Application with the provided id.
   */
  read: (id: number, deep?: boolean) => Promise<SubmitResponse>
  /**
   * Submits an Application that is no longer in DRAFT status.
   * This can be an Application in REVIEW or a later status, such as for App 2.
   */
  resubmit: (id: number, payload: Payload, type: EditFormType, rate: RateOnSubmit, status: ApplicationStatus) => Promise<SubmitResponse>
  /**
   * Saves an in progress Application as draft.
   *
   * Usually called when the user manually invokes a "Save" action to differentiate from auto-saves.
   */
  save: (id: number, payload?: Payload, status?: ApplicationStatus, fuzzy?: boolean) => Promise<{
    policyState?: PolicyState
    json?: SubmitResponse['json']
    responses: APIPayloadResponse[]
  }>
  /**
   * Select which quote to use for an Application
   */
  selectQuote: (id: number, quoteId: string, payload?: Payload) => Promise<{
    policyState?: PolicyState
    json: SubmitResponse['json']
  }>
  /**
   * Creates a new Application from an existing one.
   * Used for starting over, endorsements, cancellations.
  */
  spawn: (id: number, type?: TransactionType, payload?: APIPayloadResponse[]) => Promise<{
    json: SubmitResponse['json']
    policy: APIPolicy
    policyState?: PolicyState
  }>
  /**
   * Submits an Application for the first time.
   */
  submit: (id: number, payload: Payload, kind: ApplicationKind, rate: RateOnSubmit) => Promise<SubmitResponse>
  /**
   * Saves FormData for an in progress Application
   */
  update: (id?: number, payload?: Payload, status?: ApplicationStatus, fuzzy?: boolean) => Promise<{
    policyState?: PolicyState
    json?: SubmitResponse['json']
    responses: APIPayloadResponse[]
  }>
  /**
   * Runs an {@link RunWorkflow} on the current PolicyState.
   * Usually called when the user interacts with a button that triggers a workflow.
   *
   * Optionally accepts additional arguments to pass to the workflow.
   *
   * **NOTE: This only supports protobuf programs.**
   *
   * @example
   * // Clicking "Add Vehicle" on the Vehicles Schedule, which might trigger an NHTSA call in Lua:
   * workflow(
   *  'add_vehicle'
   *  {
   *    // data entered in the Add Vehicle form
   *    vehicle: { vin: '1234567890', price: '10000' },
   *    coverages: [1003, 1004],
   *  }
   * )
   */
  workflow: (name: string, additionalArgs?: RunWorkflowRequest['additionalArgs'], abortController?: AbortController) => Promise<SubmitResponseProtobuf>
}

/**
 * Converts a diff result map into a list that can be used to generate the same {@link DiffData} structure
 * that the Django `compare` endpoint returns.
 *
 * - Converts the casing of the keys
 * - Extracts the `Any` grpc wrappers into their primitive value forms
 */
function processDiffResults (results: Record<string, DiffResult>): { k: string, previousValue: PrimitiveValue, currentValue: PrimitiveValue }[] {
  return Object.entries(results).map(([k, v]) => {
    const camelKey = k.split('.')
      .map((part) => toCamelCase(part))
      .join('.')
    return {
      k: camelKey,
      previousValue: extractAnyWrapper<PrimitiveValue>(v.previousValue) ?? null,
      currentValue: extractAnyWrapper<PrimitiveValue>(v.currentValue) ?? null,
    }
  })
}

/**
 * Creates default application payload for the selected program from preset values defined in config
 */
function createBaseResponses (): APIPayloadResponse[] {
  const formEditStore = useFormEditStore()
  return [
    { k: FORM_INITIAL_FLAG_PATH, v: true },
    ...formEditStore.baseResponses!,
  ]
}

/**
 * Safely gets a PolicyState that can be sent to the API.
 * Prefers a working copy, will use the currently loaded state if not available.
 */
export function getPolicyStateForApi (): PolicyState {
  const authenticationStore = useAuthenticationStore()
  const protobufStore = useProtobufStore()
  const policyState = protobufStore.policyStateWorkingCopy
    ? protobufStore.getProcessedPolicyState()
    : PolicyState.clone(protobufStore.policyState as PolicyState)
  // include who is sending the request
  policyState.request = Request.create({ user: CoreUser.clone(authenticationStore.coreUser) })
  // strip out most policy data
  policyState.policy = Policy.create({
    id: policyState.policy?.id,
    uuid: policyState.policy?.uuid,
  })
  return policyState
}

/**
 * Creates endpoints related to the Application workflow.
 */export const createApplicationEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<ApplicationApiEndpoints> => {
  const applicationServiceClient = new ApplicationServiceClient(params.transport)
  const patrickServiceClient = new PatrickServiceClient(params.transport)
  const authenticationStore = useAuthenticationStore()
  const settingsStore = useSettingsStore()
  const protobufStore = useProtobufStore()
  /**
   * Reusable function for saving Protobuf application drafts.
   */
  async function saveGrpcDraft (): Promise<SubmitResponse> {
    const request = getPolicyStateForApi()
    const { response } = await applicationServiceClient.saveDraft(request)

    devtools.logGrpc({
      description: 'Update Application',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    return await hydrateSubmitResponse(request)
  }
  /**
   * Reusable function for transitioning Protobuf application statuses.
   * Applications are transitioned by setting a specific TransactionType and GenericEventType.
   */
  async function submitGrpcTxnEvent (txn: TransactionType, options: { event?: GenericEventType, protobufOnly: true, abortController?: AbortController, deep?: boolean }): Promise<SubmitResponseProtobuf>
  async function submitGrpcTxnEvent (txn: TransactionType, options?: { event?: GenericEventType, protobufOnly?: false, abortController?: AbortController, deep?: boolean }): Promise<SubmitResponse>
  async function submitGrpcTxnEvent (txn: TransactionType, options?: { event?: GenericEventType, protobufOnly?: boolean, abortController?: AbortController, deep?: boolean }): Promise<SubmitResponse | SubmitResponseProtobuf> {
    const request = getPolicyStateForApi()

    request.txn = txn
    if (options?.event) request.event = options?.event
    const { response } = await applicationServiceClient.submit(request, { abort: options?.abortController?.signal })

    devtools.logGrpc({
      description: 'Submit Application',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    await devtools.refreshLua(String(request.id))

    if (options?.protobufOnly) return { policyState: response }

    return await hydrateSubmitResponse(response, options?.deep)
  }

  async function hydrateSubmitResponse (policyState: PolicyState, deep: boolean = false): Promise<SubmitResponse & { policy: APIPolicy }> {
    const apiStore = useApiStore()

    const { attachments } = deep ? await apiStore.attachment.list(policyState.id) : { attachments: [] }
    const { json: events } = deep
      ? await apiStore.event.list({
        id: String(policyState.id),
        type: EventIDType.EventIDType_Application,
        exclude: 'LuaExecutionEvent',
      })
      : { json: [] }
    const { json: comments } = deep
      ? await apiStore.comment.list(String(policyState.policy!.id))
      : { json: [] }
    const ordered = orderBy([...comments, ...events], ['created'], ['desc'])

    // Get complete policy
    const { policy, json } = await apiStore.policy.read(policyState.policy!.id)
    policyState.policy = policy

    return {
      policyState,
      json: policyStateToApplication(policyState, attachments, ordered),
      policy: json as APIPolicy,
      status: policyState.status,
    }
  }

  const approveDjango: ApplicationApiEndpoints['approve'] = async (id) => {
    const { data } = await api.applications.approve({ path: { pk: id } })

    return {
      // @ts-expect-error(fixable): Attachment types are incorrect
      json: data.Application as APIApplication,
      status: GenericStatus.UNKNOWN,
    }
  }
  const approveGrpc: ApplicationApiEndpoints['approve'] = async () => {
    // BE will update definitions to add TransactionType.APPROVE_TXN
    return submitGrpcTxnEvent(TransactionType.NULL_TXN)
  }

  const archiveDjango: ApplicationApiEndpoints['archive'] = async (id) => {
    await api.applications.inactivate({ path: { pk: id } })
  }
  const archiveGrpc: ApplicationApiEndpoints['archive'] = async () => {
    await submitGrpcTxnEvent(TransactionType.ARCHIVE_TXN)
  }

  const assignDjango: ApplicationApiEndpoints['assign'] = async (id, assignee) => {
    if (assignee) {
      const { data } = await api.applications.assign({ path: { pk: id }, body: { assignee: assignee.userId! } })
      return {
        json: data.Application as APIApplication,
        status: GenericStatus.UNKNOWN,
      }
    }

    await api.applications.remove_assign({ path: { pk: id } })
    return readDjango(id)
  }
  const assignGrpc: ApplicationApiEndpoints['assign'] = async (_id, assignee) => {
    const request = getPolicyStateForApi()
    request.txn = TransactionType.ASSIGNEE_CHANGE_TXN

    if (assignee) {
      request.assignee = patrickUserToCoreUser(assignee)
    } else {
      delete request.assignee
    }
    const { response } = await applicationServiceClient.submit(request)

    devtools.logGrpc({
      description: 'Submit Application',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    return await hydrateSubmitResponse(response, true)
  }

  const authoriseDjango: ApplicationApiEndpoints['authorise'] = async (id) => {
    const { data } = await api.applications.authorise({ path: { pk: id } })

    return {
      // @ts-expect-error(fixable): Attachment types are incorrect
      json: data.Application as APIApplication,
      status: GenericStatus.UNKNOWN,
    }
  }
  const authoriseGrpc: ApplicationApiEndpoints['authorise'] = async () => {
    // Need to check with BE for correct TransactionType
    return submitGrpcTxnEvent(TransactionType.NULL_TXN)
  }

  const backToReviewingDjango: ApplicationApiEndpoints['backToReviewing'] = async (id) => {
    throw new Error('application.backToReviewing endpoint is not supported on Django')
  }
  const backToReviewingGrpc: ApplicationApiEndpoints['backToReviewing'] = async () => {
    return submitGrpcTxnEvent(TransactionType.BACK_TO_REVIEWING_TXN)
  }

  const bindQuoteDjango: ApplicationApiEndpoints['bindQuote'] = async () => {
    throw new Error('application.rate endpoint is not supported on Django')
  }
  const bindQuoteGrpc: ApplicationApiEndpoints['bindQuote'] = async () => {
    return submitGrpcTxnEvent(TransactionType.BIND_TXN)
  }

  const cancelDjango: ApplicationApiEndpoints['issue'] = async (id) => {
    const { data } = await api.applications.to_cancelled({ path: { pk: id } })

    return {
      // @ts-expect-error(fixable): Attachment types are incorrect
      json: data.Application as APIApplication,
      status: GenericStatus.UNKNOWN,
    }
  }
  const cancelGrpc: ApplicationApiEndpoints['issue'] = async () => {
    return submitGrpcTxnEvent(TransactionType.CANCELLATION_TXN)
  }

  /**
   * Gets the requested {@link PolicyState} for use with `compare`.
   * Will reuse the loaded application's {@link PolicyState} if the id matches.
   */
  async function getComparePolicyState (id: number): Promise<PolicyState> {
    const policyState = await (async () => {
      if (protobufStore.policyState?.id === id) return PolicyState.clone(protobufStore.policyState as PolicyState)
      const { response } = await applicationServiceClient.retrieveById(RetrieveByIdRequest.create({ id }))
      return response
    })()
    delete policyState.policy
    delete policyState.quotes
    return policyState
  }
  const compareDjango: ApplicationApiEndpoints['compare'] = async (baseId, targetId) => {
    const res = await api.applications.compare({ path: { pk: baseId, target_pk: targetId } })
    // @ts-expect-error(external): diff is actually nested under result, docs are incorrect
    return res.data.result.diff
  }
  const compareGrpc: ApplicationApiEndpoints['compare'] = async (baseId, targetId) => {
    const diffData: DiffData = { added: [], changed: [], removed: [] }
    const diffServiceClient = new PolicyStateDiffServiceClient(params.transport)
    const base = await getComparePolicyState(baseId)
    const target = await getComparePolicyState(targetId)
    const { response } = await diffServiceClient.diff({ base, target })

    devtools.logGrpc({
      description: 'Diff',
      messages: [
        { type: PolicyState, key: 'base', message: base },
        { type: PolicyState, key: 'target', message: target },
        { type: DiffResponse, key: 'diff', message: response },
      ],
    })

    for (const { k, currentValue } of processDiffResults(response.added)) {
      diffData.added.push({ k, uuid4: k, v: currentValue })
    }
    for (const { k, previousValue, currentValue } of processDiffResults(response.changed)) {
      diffData.changed.push({
        uuid4: k,
        changed: {
          k: [k, k],
          v: [previousValue, currentValue],
        },
      })
    }
    for (const { k, previousValue } of processDiffResults(response.removed)) {
      diffData.removed.push({ k, uuid4: k, v: previousValue })
    }
    return diffData
  }

  const createDjango: ApplicationApiEndpoints['create'] = async (currentProgram, payload) => {
    const responses = createBaseResponses()
    responses.push(...payload.serialize())

    const res = await api.policies.create({
      body: objectToFormData({
        program: currentProgram.id,
        data: { responses },
      }),
    })

    return {
      json: res.data.Application,
    }
  }
  const createGrpc: ApplicationApiEndpoints['create'] = async (currentProgram) => {
    const program = Program.create({
      id: currentProgram.id,
      name: currentProgram.name,
      slug: currentProgram.slug,
      ulid: currentProgram.bingoId,
    })
    const user = CoreUser.clone(authenticationStore.coreUser)

    protobufStore.setPolicyState('program', program)
    protobufStore.setPolicyState('owner', user)
    const request = protobufStore.getProcessedPolicyState()
    request.txn = TransactionType.SPAWN_TXN

    if (Sentry && !request.owner?.id && !request.owner?.ulid) {
      Sentry.captureException(`Owner ids not set on spawn! ID: ${request.owner?.id}, ULID: ${request.owner?.ulid}`)
    }

    const { response } = await applicationServiceClient.newApplication(request)

    devtools.logGrpc({
      description: 'Create Application',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    return await hydrateSubmitResponse(response)
  }

  const declineDjango: ApplicationApiEndpoints['decline'] = async (id) => {
    const { data } = await api.applications.decline({ path: { pk: id }, body: {} })

    return {
      json: data.Application,
      status: GenericStatus.UNKNOWN,
    }
  }
  const declineGrpc: ApplicationApiEndpoints['decline'] = async () => {
    return submitGrpcTxnEvent(TransactionType.DECLINE_TXN, { deep: true })
  }

  const deleteDjango: ApplicationApiEndpoints['delete'] = async (id) => {
    await api.applications.delete({ path: { pk: id } })
  }
  const deleteGrpc: ApplicationApiEndpoints['delete'] = async () => {
    await submitGrpcTxnEvent(TransactionType.ARCHIVE_TXN)
  }

  const draftDjango: ApplicationApiEndpoints['draft'] = async (id) => {
    const { data } = await api.applications.to_draft({ path: { pk: id } })

    return {
      json: data.Application,
      status: GenericStatus.UNKNOWN,
    }
  }
  const draftGrpc: ApplicationApiEndpoints['draft'] = async () => {
    return submitGrpcTxnEvent(TransactionType.DRAFT_TXN, { deep: true })
  }

  const finaliseDjango: ApplicationApiEndpoints['finalise'] = async (id) => {
    const { data } = await api.applications.finalise({ path: { pk: id } })

    return {
      // @ts-expect-error(fixable): Attachment types are incorrect
      json: data.Application as APIApplication,
      status: GenericStatus.UNKNOWN,
    }
  }
  const finaliseGrpc: ApplicationApiEndpoints['finalise'] = async () => {
    return submitGrpcTxnEvent(TransactionType.AUTHORISE_TXN)
  }

  const freshnessDjango: ApplicationApiEndpoints['freshness'] = async (id) => {
    const res = await api.applications.freshness({ path: { pk: id } })
    return res.data.modified
  }
  const freshnessGrpc: ApplicationApiEndpoints['freshness'] = async (id) => {
    const apiStore = useApiStore()
    const { events } = await apiStore.event.list({
      id: String(id),
      type: EventIDType.EventIDType_Application,
      exclude: 'LuaExecutionEvent',
      limit: 1,
      logLevel: 'debug',
    })

    return timestampToString(events?.length ? events![0].created : null, true) ?? '2000-01-01T00:00:00.000Z'
  }

  const generateQuoteDocDjango: ApplicationApiEndpoints['generateQuoteDoc'] = async (id) => {
    await api.applications.generate_quote_doc({ path: { pk: id } })
    return readDjango(id)
  }
  const generateQuoteDocGrpc: ApplicationApiEndpoints['generateQuoteDoc'] = async () => {
    return submitGrpcTxnEvent(TransactionType.GENERATE_DOCUMENT_TXN)
  }

  const issueDjango: ApplicationApiEndpoints['issue'] = async (id, body, fuzzy) => {
    const { data } = await api.applications.to_issued({
      path: { pk: id },
      ...(body
        ? {
            body: objectToFormData({
              responses: body,
              policy_state: protobufStore.serializePolicyState(),
            }),
          }
        : {}),
      query: fuzzy ? { mode: 'fuzzy' } : {},
    })

    return {
      // @ts-expect-error(fixable): Attachment types are incorrect
      json: data.Application as APIApplication,
      status: GenericStatus.UNKNOWN,
    }
  }
  const issueGrpc: ApplicationApiEndpoints['issue'] = async (_id, _body, fuzzy) => {
    if (fuzzy) return await saveGrpcDraft()
    return submitGrpcTxnEvent(TransactionType.ISSUANCE_TXN)
  }

  const issuanceDataDjango: ApplicationApiEndpoints['issuanceData'] = async (id, body, fuzzy) => {
    const { data } = await api.applications.apply_issuance_data({
      path: { pk: id },
      body: objectToFormData({
        data: { responses: body },
        policy_state: protobufStore.serializePolicyState(),
      }),
      query: fuzzy ? { mode: 'fuzzy' } : {},
    })

    return {
      // @ts-expect-error(fixable): Attachment types are incorrect
      json: data.Application as APIApplication,
      status: GenericStatus.UNKNOWN,
    }
  }
  const issuanceDataGrpc: ApplicationApiEndpoints['issuanceData'] = async (_id, _body, fuzzy) => {
    if (fuzzy) return await saveGrpcDraft()
    return submitGrpcTxnEvent(TransactionType.ISSUANCE_TXN)
  }

  const lockDjango: ApplicationApiEndpoints['lock'] = async (id) => {
    await api.applications.lock({
      path: { pk: id },
      // @ts-expect-error(external): lock_code can be null, docs are incorrect
      body: { lock_code: null },
    })

    return readDjango(id)
  }
  const lockGrpc: ApplicationApiEndpoints['lock'] = async () => {
    const request = getPolicyStateForApi()
    // TODO: BE will update definitions to add lock info, we will need to set that

    const { response } = await applicationServiceClient.saveDraft(request)

    devtools.logGrpc({
      description: 'Lock Application',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    return {
      responses: [],
      policyState: request,
      json: policyStateToApplication(request),
    }
  }

  const lostDjango: ApplicationApiEndpoints['lost'] = async (id) => {
    const { data } = await api.applications.lost({ path: { pk: id }, body: {} })

    return {
      json: data.Application,
      status: GenericStatus.UNKNOWN,
    }
  }
  const lostGrpc: ApplicationApiEndpoints['lost'] = async () => {
    return submitGrpcTxnEvent(TransactionType.QUOTE_LOST_TXN)
  }

  const pendingIssueDjango: ApplicationApiEndpoints['pendingIssue'] = async (id, responses, binary) => {
    let body = responses
      ? { data: { responses: responses.serialize() } }
      : undefined

    // @ts-expect-error(fixable): Cannot currently match types of FormData
    body = (body && binary)
      ? objectToFormData({ ...body, binary })
      : body

    const { data } = await api.applications.to_pending_issue({
      path: { pk: id },
      ...(body ? { body } : {}),
      query: {},
    })

    return {
      // @ts-expect-error(fixable): Attachment types are incorrect
      json: data.Application as APIApplication,
      status: GenericStatus.REQUESTED,
    }
  }
  const pendingIssueGrpc: ApplicationApiEndpoints['pendingIssue'] = async () => {
    // Will need to send some additional info to BE
    return submitGrpcTxnEvent(TransactionType.BIND_TXN)
  }

  const qualityControlDjango: ApplicationApiEndpoints['qualityControl'] = async (id, action) => {
    const { data } = await api.applications.quality_control({ path: { pk: id }, body: { action } })

    return {
      // @ts-expect-error(fixable): Attachment types are incorrect
      json: data.Application as APIApplication,
      status: GenericStatus.UNKNOWN,
    }
  }
  const qualityControlGrpc: ApplicationApiEndpoints['qualityControl'] = async (id, action) => {
    switch (action) {
      case 'PASS':
        return submitGrpcTxnEvent(TransactionType.QC_PASS_TXN, { deep: true })
      case 'FAIL':
        return submitGrpcTxnEvent(TransactionType.QC_FAIL_TXN, { deep: true })
      case 'SKIP':
        return submitGrpcTxnEvent(TransactionType.QC_SKIP_TXN, { deep: true })
      default:
        throw new Error(`Invalid quality control action: ${action}. Must be PASS, FAIL, or SKIP`)
    }
  }

  const qualityRecheckDjango: ApplicationApiEndpoints['qualityRecheck'] = async (id) => {
    const { data } = await api.applications.force_quality_recheck({ path: { pk: id } })

    return {
      // @ts-expect-error(fixable): Attachment types are incorrect
      json: data.Application as APIApplication,
      status: GenericStatus.UNKNOWN,
    }
  }
  const qualityRecheckGrpc: ApplicationApiEndpoints['qualityRecheck'] = async () => {
    // Quality Control actions can be invoked on the feed, deeply load so we also fetch updated events at the same time
    return submitGrpcTxnEvent(TransactionType.BACK_TO_QC_TXN, { deep: true })
  }

  const quoteDjango: ApplicationApiEndpoints['quote'] = async (id, body, _kind, fuzzy) => {
    const { data } = await api.applications.quote({
      path: { pk: id },
      body,
      query: fuzzy ? { mode: 'fuzzy' } : {},
    })

    return {
      json: data.Application,
      status: GenericStatus.UNKNOWN,
    }
  }
  const quoteGrpc: ApplicationApiEndpoints['quote'] = async (_id, _quotes, kind, fuzzy) => {
    let txn = TransactionType.QUOTE_TXN
    switch (true) {
      // Saving a draft
      case fuzzy:
        txn = TransactionType.SAVE_DRAFT_TXN
        break
      case kind === Kind.KIND_ENDORSEMENT:
        txn = TransactionType.ENDORSEMENT_QUOTE_TXN
        break
      case kind === Kind.KIND_CANCELLATION:
        txn = TransactionType.CANCELLATION_QUOTE_TXN
        break
    }

    return await submitGrpcTxnEvent(txn)
  }

  const rateDjango: ApplicationApiEndpoints['rate'] = async () => {
    throw new Error('application.rate endpoint is not supported on Django')
  }
  const rateGrpc: ApplicationApiEndpoints['rate'] = async (txn, abortController) => {
    return await submitGrpcTxnEvent(txn, { protobufOnly: true, abortController })
  }

  const readDjango: ApplicationApiEndpoints['read'] = async (id) => {
    const res = await api.applications.read({ path: { pk: id }, query: {} })
    return {
      json: res.data.Application,
      status: GenericStatus.UNKNOWN,
    }
  }
  const readGrpc: ApplicationApiEndpoints['read'] = async (id, deep = true) => {
    const request = ReadPolicyStateRequest.create({ id })
    const { response } = await patrickServiceClient.readPolicyState(request)
    const policyState = PolicyState.create(response.policyState)

    // redirect to homepage if current program matches policy state program
    if (policyState.program?.id !== authenticationStore.currentProgram?.id) {
      const code = 'PROGRAM_ID_MISMATCH'
      throw new AxiosError('', code, undefined, undefined, {
        status: 400,
        statusText: '',
        data: { error: { code } },
        headers: {},
        config: { headers: {} as AxiosResponseHeaders },
      })
    }

    devtools.logGrpc({
      description: 'Read Application',
      messages: [
        { type: RetrieveByIdRequest, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: policyState },
      ],
    })

    await devtools.refreshLua(String(request.id))

    return await hydrateSubmitResponse(policyState, deep)
  }

  const resubmitDjango: ApplicationApiEndpoints['resubmit'] = async (id, payload, _type, _rate, status) => {
    const { json } = await updateDjango(id, payload, status, false)

    return {
      json: json!,
      status: applicationStatusToGenericStatus(json!.status as ApplicationStatus, !!json?.is_cancellation),
    }
  }
  const resubmitGrpc: ApplicationApiEndpoints['resubmit'] = async (id, payload, type, rate) => {
    return submitGrpcTxnEvent(type === 'APP_PENDING_INFO'
      ? TransactionType.BIND_AMENDED_TXN
      : TransactionType.INQUIRY_TXN)
  }

  const saveDjango: ApplicationApiEndpoints['save'] = async (id, payload, status, fuzzy) => {
    return updateDjango(id, payload, status, fuzzy)
  }
  const saveGrpc: ApplicationApiEndpoints['save'] = async () => {
    const { policyState, json } = await submitGrpcTxnEvent(TransactionType.SAVE_DRAFT_TXN)
    return {
      policyState,
      json,
      responses: [],
    }
  }

  const selectQuoteDjango: ApplicationApiEndpoints['selectQuote'] = async (id, quoteUuid, payload) => {
    const res = await api.applications.selectquote({
      path: { pk: id },
      body: objectToFormData({
        selected: [quoteUuid],
        ...(payload
          ? { injected_response: payload.serialize() }
          : {}),
      }),
    })

    return {
      json: res.data.Application,
    }
  }
  const selectQuoteGrpc: ApplicationApiEndpoints['selectQuote'] = async (_id, quoteUuid) => {
    const request = protobufStore.getProcessedPolicyState()
    request.selectedQuote = request.quotes!.quotes!.find((q) => q.uuid4 === quoteUuid)

    const { response } = await applicationServiceClient.submit(request)

    devtools.logGrpc({
      description: 'Select Quote',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    return await hydrateSubmitResponse(response)
  }

  const spawnDjango: ApplicationApiEndpoints['spawn'] = async (id, txn, cancellationReason) => {
    let body: paths['/api/v1/applications/spawn/']['post']['requestBody']['content']['application/json']
    switch (txn) {
      case TransactionType.CANCELLATION_SPAWN_TXN:
        body = {
          application_id: id,
          kind: 'ENDORSEMENT',
          new_policy: false,
          clear_app_data: false,
          clear_quote_data: true,
          clear_derived_data: true,
          is_cancellation: true,
          injected_responses: cancellationReason,
        }
        break
      case TransactionType.ENDORSEMENT_SPAWN_TXN:
        body = {
          application_id: id,
          kind: 'ENDORSEMENT',
          new_policy: false,
          clear_app_data: false,
          clear_quote_data: true,
          clear_derived_data: true,
          injected_responses: [{ k: FORM_INITIAL_FLAG_PATH, v: true }],
        }
        break
      case TransactionType.RENEWAL_SPAWN_TXN:
        body = {
          application_id: id,
          kind: 'RENEWAL',
          new_policy: settingsStore.renewsToNewPolicy,
          clear_app_data: false,
          clear_quote_data: true,
          clear_derived_data: true,
          terminate_self: false,
          archive_self: false,
          injected_responses: [{ k: FORM_INITIAL_FLAG_PATH, v: true }],
        }
        break
      case TransactionType.RECLAIM_SPAWN_TXN:
        body = {
          application_id: id,
          kind: 'RECLAIM',
          new_policy: true,
          clear_app_data: false,
          clear_quote_data: true,
          clear_derived_data: true,
          terminate_self: false,
          archive_self: false,
        }
        break
      default:
        body = {
          application_id: id,
          kind: 'APPLICATION',
          new_policy: true,
          clear_app_data: false,
          clear_quote_data: true,
          clear_derived_data: true,
        }
    }

    const { data } = await api.applications.spawn({ body })

    return {
      json: data.Application,
      policy: data.Policy,
    }
  }
  const spawnGrpc: ApplicationApiEndpoints['spawn'] = async (_id, txn = TransactionType.SPAWN_TXN) => {
    const apiStore = useApiStore()
    const request = {
      ...getPolicyStateForApi(),
      id: 0,
      uuid: '',
      txn,
    }

    switch (txn) {
      case TransactionType.CANCELLATION_SPAWN_TXN: {
        const { policy } = await apiStore.policy.read(request.policy!.id)
        request.txn = TransactionType.CANCELLATION_SPAWN_TXN
        request.policy = policy
        request.kind = Kind.KIND_ENDORSEMENT
        break
      }
      case TransactionType.SPAWN_TXN: {
        request.policy = undefined
        break
      }
      case TransactionType.ENDORSEMENT_SPAWN_TXN: {
        const { policy } = await apiStore.policy.read(request.policy!.id)
        request.txn = TransactionType.ENDORSEMENT_SPAWN_TXN
        request.policy = policy
        request.kind = Kind.KIND_ENDORSEMENT
        break
      }
      case TransactionType.RENEWAL_SPAWN_TXN: {
        const { policy } = await apiStore.policy.read(request.policy!.id)
        request.txn = TransactionType.RENEWAL_SPAWN_TXN
        request.policy = Policy.create({
          parent: policy,
        })
        request.kind = Kind.KIND_RENEWAL
        break
      }
      case TransactionType.RECLAIM_SPAWN_TXN: {
        const { policy } = await apiStore.policy.read(request.policy!.id)
        request.txn = TransactionType.RECLAIM_SPAWN_TXN
        request.policy = Policy.create({
          parent: policy,
        })
        request.kind = Kind.KIND_RECLAIM
        break
      }
    }

    const { response } = await applicationServiceClient.newApplication(request)

    devtools.logGrpc({
      description: 'Spawn Application',
      messages: [
        { type: PolicyState, key: 'request', message: request },
        { type: PolicyState, key: 'response', message: response },
      ],
    })

    return await hydrateSubmitResponse(response, true)
  }

  const submitDjango: ApplicationApiEndpoints['submit'] = async (id, payload, kind, rate) => {
    let formData

    const { json, responses } = await updateDjango(id, payload, 'DRAFT', false)
    const rateResult = await rate(json as APIApplication, responses)

    // Django program uses GPRC rating service, pass formData so rating can be done server side
    if (rateResult) {
      const status = rateResult.response.decision === QuoteDecision.REFER_QUOTE_DEC || kind === 'ENDORSEMENT' ? 'REVIEW' : 'QUOTED'
      formData = objectToFormData({
        status,
        binary: rateResult.binaryQuoteSet,
      })
      if (status === 'QUOTED') {
        const quotes = rateResult.quotes.map((q) => ({ responses: q.serialize() }))
        formData.append('quote_data', JSON.stringify({ quotes }))
      }
    }
    // @ts-expect-error(external): API says body is required
    const { data } = await api.applications.submit({ path: { pk: id }, body: formData })

    return {
      json: data.Application,
      status: applicationStatusToGenericStatus(data.Application.status as ApplicationStatus, !!data.Application?.is_cancellation),
    }
  }
  const submitGrpc: ApplicationApiEndpoints['submit'] = async (_id, _payload, kind) => {
    return await submitGrpcTxnEvent(
      kind === 'ENDORSEMENT' ? TransactionType.ENDORSEMENT_INQUIRY_TXN : TransactionType.INQUIRY_TXN,
      { event: GenericEventType.INQUIRY_EVENT },
    )
  }

  const updateDjango: ApplicationApiEndpoints['update'] = async (id, payload, status, fuzzy) => {
    if (!id || !payload) throw new Error('Application id and payload required for django update endpoint')

    const responses = payload.serialize()
    const body = objectToFormData({
      data: { responses },
    })
    if (status === 'REQUESTED_TO_BIND') body.set('transition', 'true')

    const { data } = await api.applications.update({
      path: { pk: id },
      body,
      query: fuzzy ? { mode: 'fuzzy' } : {}, // "fuzzy" update doesn't return any value from api
    })

    const app = data.Application as APIApplication | undefined

    return {
      json: app,
      responses: app?.computedData?.responses ?? payload?.serialize(),
      status: applicationStatusToGenericStatus(app?.status as ApplicationStatus, !!app?.is_cancellation),
    }
  }
  const updateGrpc: ApplicationApiEndpoints['update'] = async () => {
    return {
      ...await saveGrpcDraft(),
      responses: [],
    }
  }

  const workflowDjango: ApplicationApiEndpoints['workflow'] = async () => {
    throw new Error('Workflow not supported in Django')
  }
  const workflowGrpc: ApplicationApiEndpoints['workflow'] = async (name, additionalArgs) => {
    const policyState = getPolicyStateForApi()
    const request = RunWorkflowRequest.create({
      program: authenticationStore.slug,
      name,
      additionalArgs,
      policyState,
    })

    const { response } = await applicationServiceClient.runWorkflow(request)

    if (!response.policyState) {
      throw new Error('Workflow did not return a policy state')
    }

    devtools.logGrpc({
      description: 'Run Workflow',
      messages: [
        { type: RunWorkflowRequest, key: 'request', message: request },
        { type: RunWorkflowResponse, key: 'response', message: response },
      ],
    })

    return { policyState: response.policyState }
  }

  return {
    django: {
      approve: approveDjango,
      archive: archiveDjango,
      assign: assignDjango,
      authorise: authoriseDjango,
      backToReviewing: backToReviewingDjango,
      bindQuote: bindQuoteDjango,
      cancel: cancelDjango,
      compare: compareDjango,
      create: createDjango,
      delete: deleteDjango,
      decline: declineDjango,
      draft: draftDjango,
      finalise: finaliseDjango,
      freshness: freshnessDjango,
      generateQuoteDoc: generateQuoteDocDjango,
      issue: issueDjango,
      issuanceData: issuanceDataDjango,
      lock: lockDjango,
      lost: lostDjango,
      pendingIssue: pendingIssueDjango,
      qualityControl: qualityControlDjango,
      qualityRecheck: qualityRecheckDjango,
      quote: quoteDjango,
      rate: rateDjango,
      read: readDjango,
      resubmit: resubmitDjango,
      save: saveDjango,
      selectQuote: selectQuoteDjango,
      spawn: spawnDjango,
      submit: submitDjango,
      update: updateDjango,
      workflow: workflowDjango,
    },
    grpc: {
      approve: approveGrpc,
      archive: archiveGrpc,
      assign: assignGrpc,
      authorise: authoriseGrpc,
      backToReviewing: backToReviewingGrpc,
      bindQuote: bindQuoteGrpc,
      cancel: cancelGrpc,
      compare: compareGrpc,
      create: createGrpc,
      delete: deleteGrpc,
      decline: declineGrpc,
      draft: draftGrpc,
      finalise: finaliseGrpc,
      freshness: freshnessGrpc,
      generateQuoteDoc: generateQuoteDocGrpc,
      issue: issueGrpc,
      issuanceData: issuanceDataGrpc,
      lock: lockGrpc,
      lost: lostGrpc,
      pendingIssue: pendingIssueGrpc,
      qualityControl: qualityControlGrpc,
      qualityRecheck: qualityRecheckGrpc,
      quote: quoteGrpc,
      rate: rateGrpc,
      read: readGrpc,
      resubmit: resubmitGrpc,
      save: saveGrpc,
      selectQuote: selectQuoteGrpc,
      spawn: spawnGrpc,
      submit: submitGrpc,
      update: updateGrpc,
      workflow: workflowGrpc,
    },
  }
}
