<template>
  <v-navigation-drawer
    id="sidebar"
    v-model="isOpen"
    width="325"
    temporary
    floating
  >
    <v-toolbar height="60" flat color="darknavyblue">
      <v-toolbar-title class="fs-20 text-white">
        POLICYFLY <span v-if="isBetaEnv">(Beta)</span>
      </v-toolbar-title>
      <div class="flex-grow-1" />
      <v-toolbar-items>
        <v-btn
          id="sidebar--close"
          variant="text"
          icon
          @click="toggle"
        >
          <v-icon color="white" icon="mdi-close" />
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-list class="sidebar-list">
      <v-list-item
        v-for="item of items"
        :id="item.id"
        :key="item.id"
        :active="isActive(item)"
        base-color="subheadgrey"
        class="sidebar-content"
        @click="onSelect(item)"
      >
        <template #prepend>
          <v-icon :icon="item.icon" />
        </template>
        <template #default>
          <v-list-item-title class="fs-16 font-weight-medium" v-text="item.label" />
        </template>
        <template v-if="item.badgeCount" #append>
          <v-chip
            label
            size="small"
            color="error"
            v-text="item.badgeCount || 0"
          />
        </template>
      </v-list-item>
    </v-list>
    <template #append>
      <div v-if="showTheme" class="px-6">
        <p>Theme</p>
        <v-btn-toggle
          v-model="theme"
          mandatory
          border
          divided
          width="100%"
        >
          <v-btn value="light">
            <v-icon>mdi-white-balance-sunny</v-icon>
          </v-btn>
          <v-btn value="dark">
            <v-icon>mdi-moon-waxing-crescent</v-icon>
          </v-btn>
          <v-btn value="pink">
            <v-icon :color="pinkTheme">
              mdi-circle
            </v-icon>
          </v-btn>
          <v-btn value="auto">
            <v-icon>mdi-laptop</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
      <SupportLink class="pa-5" stacked />
      <v-divider />
      <v-row
        no-gutters
        class="pa-4 fs-12 text-center"
        align="center"
        justify="center"
      >
        <router-link style="text-decoration:none" class="text-darknavyblue px-4" :to="{ name: routeNames.TERMS }">
          Terms
        </router-link>
        <span>&bull;</span>
        <router-link style="text-decoration:none" class="text-darknavyblue px-4" :to="{ name: routeNames.PRIVACY }">
          Privacy Policy
        </router-link>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { SchemaSettings_ProgramRating } from '@policyfly/protobuf'
import { useColorMode } from '@vueuse/core'
import { defineComponent, ref, onMounted, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTheme } from 'vuetify'

import { useNotification } from '@/composables/notification'
import { usePermission } from '@/composables/permission'
import { useAuthenticationStore } from '@/stores/authentication'
import { useCmsStore } from '@/stores/cms'
import { useGlobalStore } from '@/stores/global'
import { useSettingsStore } from '@/stores/settings'
import SupportLink from 'Common/SupportLink/SupportLink.vue'

import { FORM_NAVIGATION_WARNING } from '@/constants'
import routeNames from '@/router/routeNames'

import type { AgencyPermission, GeneralPermission } from '@/composables/permission'
import type { RouteNamesValues } from 'types/router'

export interface NavbarItem {
  label: string
  icon: string
  id: string
  badgeCount?: number
  link?: {
    name: RouteNamesValues
    params?: Record<string, string>
  }
  action?: (args?: unknown) => unknown
}

export default defineComponent({
  name: 'Sidebar',

  components: {
    SupportLink,
  },

  setup () {
    const { userConfirm } = useNotification()
    const authenticationStore = useAuthenticationStore()
    const router = useRouter()
    const route = useRoute()

    // beta
    const globalStore = useGlobalStore()
    const isBetaEnv = computed(() => globalStore.isBetaEnv)

    // menu state
    const isOpen = ref(false)
    function toggle (): void {
      isOpen.value = !isOpen.value
    }
    watch(() => route.name, () => {
      isOpen.value = false
    })

    // items
    const { checkPermission } = usePermission<AgencyPermission | GeneralPermission>()
    const settingsStore = useSettingsStore()
    const isSuperuser = computed(() => authenticationStore.userRole === 'SUPERUSER')
    async function logoutUser (): Promise<void> {
      await router.push({ name: routeNames.ACCOUNT_LOGOUT })
    }
    const items = computed<NavbarItem[]>(() => {
      const allItems: NavbarItem[] = [
        {
          label: 'Home',
          icon: 'mdi-home',
          id: 'sidebar--home',
          link: { name: routeNames.HOME },
        },
      ]
      if (settingsStore.reportingPage && authenticationStore.isProgmin) {
        allItems.push({
          label: 'Reporting',
          icon: 'mdi-wrench',
          id: 'sidebar--reporting',
          link: { name: routeNames.REPORTING },
        })
      }
      if (settingsStore.documentManagement && authenticationStore.isProgmin) {
        allItems.push({
          label: 'Document Manager',
          icon: 'mdi-file-multiple',
          id: 'sidebar--documents',
          link: { name: routeNames.DOCUMENT_DOCUMENTSLIST },
        })
      }
      if (checkPermission('myAgencyView')) {
        if (authenticationStore.currentAgencies.length === 1) {
          const [agency] = authenticationStore.currentAgencies
          const id = agency.bingoId ?? String(agency.id)
          allItems.push({
            label: 'My Agency',
            icon: 'mdi-account-multiple',
            id: 'sidebar--my-agency',
            link: { name: routeNames.AGENCY_DETAIL, params: { id } },
          })
        } else if (authenticationStore.currentAgencies.length > 1) {
          allItems.push({
            label: 'My Agencies',
            icon: 'mdi-account-multiple',
            id: 'sidebar--my-agencies',
            link: { name: routeNames.MY_AGENCIES },
          })
        }
      }
      if (checkPermission('internalUserListView')) {
        allItems.push({
          label: 'Internal Users',
          icon: 'mdi-account-multiple',
          id: 'sidebar--internal-users',
          link: { name: routeNames.USER_USERSLIST },
        })
      }
      if (checkPermission('agencyListView')) {
        allItems.push({
          label: 'Agencies',
          icon: 'mdi-account-supervisor-circle',
          id: 'sidebar--agencies',
          link: { name: routeNames.AGENCY_LIST },
        })
      }
      if (settingsStore.rating === SchemaSettings_ProgramRating.PROGRAM_RATING_ASSISTED && isSuperuser.value) {
        allItems.push({
          label: 'Rating',
          icon: 'mdi-cog',
          id: 'sidebar--rating',
          link: { name: routeNames.RATING },
        })
      }
      allItems.push({
        label: 'My Account',
        icon: 'mdi-account-circle',
        id: 'sidebar--my-account',
        link: { name: routeNames.ACCOUNT_INDEX },
      })

      if (isSuperuser.value) {
        allItems.push({
          label: 'Admin',
          icon: 'mdi-account-star',
          id: 'sidebar--admin',
          link: { name: routeNames.ADMIN_INDEX },
        })
      }
      if (cmsStore.releaseNotes.length) {
        allItems.push({
          label: 'Program Alerts',
          icon: 'mdi-format-list-checks',
          id: 'sidebar--release-notes',
          link: { name: routeNames.RELEASE_NOTES },
          badgeCount: cmsStore.countSinceLastRead,
        })
      }

      allItems.push({
        label: 'Logout',
        icon: 'mdi-logout-variant',
        id: 'sidebar--logout',
        action: logoutUser,
      })
      return allItems
    })
    function isActive (item: NavbarItem): boolean {
      if (item.link) {
        return route.name === item.link.name
      }
      return false
    }
    async function onSelect (item: NavbarItem): Promise<void> {
      isOpen.value = false
      if (route.meta?.form && !await userConfirm({ title: FORM_NAVIGATION_WARNING })) return
      if (item.link && route.name !== item.link.name) {
        await router.push(item.link)
      }
      if (item.action) item.action()
    }

    // program alerts
    const cmsStore = useCmsStore()
    async function fetchNotes (): Promise<void> {
      if (!authenticationStore.userId || !authenticationStore.programId) return
      await cmsStore.getNotes()
      if (cmsStore.unreadNotes.some((note) => note.priority)) {
        const response = await userConfirm({
          title: 'There are new program alerts',
          body: 'There are new program alerts that are important to your experience on the PolicyFly platform and/or have impact on the guidelines for this program. Click “View Alerts” to learn more.',
          confirmButton: 'View Alerts',
          cancelButton: 'Mark as Read',
          maxWidth: 520,
        })
        if (!response) {
          cmsStore.setLastRead(new Date().toISOString())
        } else {
          await router.push({ name: routeNames.RELEASE_NOTES })
        }
      }
    }
    watch([
      () => authenticationStore.userId,
      () => authenticationStore.programId,
    ], async () => {
      await fetchNotes()
    })
    onMounted(async () => {
      await fetchNotes()
    })

    // theme
    const showTheme = computed(() => {
      if (isBetaEnv.value) return false
      return import.meta.env.VITE_THEME_SUPPORT === 'true'
    })
    const { themes } = useTheme()
    const pinkTheme = computed(() => themes.value.pink.colors.navyblue)
    const { store: theme } = useColorMode()

    return {
      routeNames,
      // beta
      isBetaEnv,
      // menu state
      isOpen,
      toggle,
      // items
      items,
      isActive,
      onSelect,
      // theme
      showTheme,
      pinkTheme,
      theme,
      // test
      fetchNotes,
    }
  },
})
</script>

<style lang="sass" scoped>
.v-list-item--active
  color: rgb(var(--v-theme-on-surface)) !important
.v-list-item--link:hover
  .v-list-item-title
    color: rgb(var(--v-theme-on-surface)) !important
  .mdi
    color: rgb(var(--v-theme-on-surface)) !important
// @vuetify-override
:deep(.v-navigation-drawer__append)
  overflow: visible
</style>
