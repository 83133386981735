import { displayIf } from 'builders/props'

import type { Condition } from '@policyfly/schema/types/shared/displayIf'
import type { DropdownItemObject } from '@policyfly/schema/types/shared/dropdown'
import type { FormComponent } from '@policyfly/schema/types/shared/formComponent'
import type { QuoteFormSource } from 'types/schema/quote'

export type RatingBasis = 'Trucks' | 'Drivers' | 'Plates'

export const EXCESS_POLICY_PATH = 'excessPolicy'
export const BRIT_FORM_PATH = 'britForm'

export const includeIfExcess: Condition<'responses'> = { path: EXCESS_POLICY_PATH, source: 'responses', permittedValues: [true] }
export const includeIfPrimary: Condition<'responses'> = { path: EXCESS_POLICY_PATH, source: 'responses', permittedValues: [false] }
export const includeIfBrit: Condition<'responses'> = { path: BRIT_FORM_PATH, source: 'responses', permittedValues: [true] }
export const includeIfBroad: Condition<'responses'> = { path: BRIT_FORM_PATH, source: 'responses', permittedValues: [false] }

export const targetGoods: { label: string, path: string, responsePath: string }[] = [
  { label: 'Diapers', path: 'diapers' },
  { label: 'Baby Formula', path: 'babyFormula' },
  { label: 'Tobacco', path: 'tobacco' },
  { label: 'Cigars', path: 'cigars' },
  { label: 'Cigarettes', path: 'cigarettes' },
  { label: 'Wine', path: 'wine' },
  { label: 'Liquor', path: 'liquor' },
  { label: 'Non-Ferrous Metals', path: 'metals' },
  { label: 'Garments', path: 'garments' },
  { label: 'Seafood', path: 'seafood' },
  { label: 'Electronics', path: 'electronics' },
].map((t) => ({
  ...t,
  responsePath: `${t.path}.response`,
}))

export const commodityTypeItems: DropdownItemObject[] = [
  { text: 'Agricultural', hint: 'Grain, Animal Feed, Straw Bales', value: 'agricultural' },
  { text: 'Auto Haulers', hint: 'New Vehicles, Used Vehicles (excludes Motor Bikes and Mobile Homes)', value: 'autoHaulers' },
  { text: 'Boat Haulers', value: 'boatHaulers' },
  { text: 'Coal Haulers', value: 'coalHaulers' },
  { text: 'Contractors & Construction', hint: 'Building Material', value: 'contractors' },
  { text: 'Cotton', value: 'cotton' },
  { text: 'Dry Goods', hint: 'Canned Goods, Beverages, Glass', value: 'dryGoods' },
  { text: 'Dump', hint: 'Sand, Gravel, Fracked Sand', value: 'dump' },
  { text: 'Eggs', value: 'eggs' },
  { text: 'Furniture', hint: 'Office Equipment, Office Supplies (excludes Non-Residential Movers)', value: 'furniture' },
  { text: 'Garbage / Refuse Haulers', value: 'garbageHaulers' },
  { text: 'Household Goods (Movers)', value: 'householdGoods' },
  { text: 'Intermodal Containers', value: 'intermodal' },
  { text: 'Livestock Haulers', value: 'livestockHaulers' },
  { text: 'Logging', hint: 'Lumber, Logs', value: 'logging' },
  { text: 'Machinery & Equipment', hint: 'Logging Equipment, Machinery Plant / Cranes, Mining, Mobile Cranes, Agricultural', value: 'machinery' },
  { text: 'Military Equipment', value: 'military' },
  { text: 'Milk (Non-Reefer)', value: 'milk' },
  { text: 'Mobile / Modular Home Haulers', value: 'mobileHome' },
  { text: 'Nursery Stock / Plants / Flowers', value: 'nurseryPlants' },
  { text: 'On Hook', value: 'onHook' },
  { text: 'Other', value: 'other' },
  { text: 'Oversize / Overweight', hint: 'Escorted Loads', value: 'oversizeOverweight' },
  { text: 'Paper / Plastic', value: 'paper' },
  { text: 'Refrigerated', value: 'refrigerated' },
  { text: 'Scrap Materials (Recycling)', hint: 'Metal, Plastic', value: 'scrap' },
  { text: 'Tanker (Hazmat)', value: 'tankerHazmat' },
  { text: 'Tanker (Non-Hazmat)', value: 'tankerNonHazmat' },
  { text: 'Target Goods', hint: targetGoods.map((v) => v.label).join(', '), value: 'targetGoods' },
]

export const commodityTypeMap = commodityTypeItems.reduce<Record<string, string>>((acc, item) => Object.assign(acc, { [item.value as string]: item.text }), {})

export function historyPath ({ prefix, index, suffix }: { prefix: string, index: number, suffix: string }): string {
  return prefix + index.toString() + suffix
}

type GrossReceiptsHistoryParams = { prefix: string, index: number, hideLabels?: boolean, fillSpace?: boolean }
export function grossReceiptsHistoryRow ({ prefix, index, hideLabels = false, fillSpace = false }: GrossReceiptsHistoryParams): FormComponent<QuoteFormSource>[] {
  const effectiveDate: FormComponent<QuoteFormSource> = {
    type: 'static',
    data: {
      path: historyPath({ prefix, index, suffix: '.effectiveDate' }),
      source: 'responses',
    },
    class: index === 0 ? 'mt-8' : 'mt-n4',
    colSize: 2,
    displayIf: displayIf(
      { path: 'grossReceiptsBasisOverride', source: 'local', permittedValues: [true] },
    ),
    formatter: 'date',
  }
  const expirationDate: FormComponent<QuoteFormSource> = {
    type: 'static',
    data: {
      path: historyPath({ prefix, index, suffix: '.expirationDate' }),
      source: 'responses',
    },
    class: index === 0 ? 'mt-8' : 'mt-n4',
    colSize: 2,
    displayIf: displayIf(
      { path: 'grossReceiptsBasisOverride', source: 'local', permittedValues: [true] },
    ),
    formatter: 'date',
  }
  const grossReceipts: FormComponent<QuoteFormSource> = {
    type: 'static',
    data: {
      path: historyPath({ prefix, index, suffix: '.grossReceipts' }),
      source: 'responses',
    },
    class: index === 0 ? 'mt-8' : 'mt-n4',
    colSize: 2,
    displayIf: displayIf(
      { path: 'grossReceiptsBasisOverride', source: 'local', permittedValues: [true] },
    ),
    formatter: 'currency',
  }
  const mileage: FormComponent<QuoteFormSource> = {
    type: 'static',
    data: {
      path: historyPath({ prefix, index, suffix: '.mileage' }),
      source: 'responses',
    },
    class: index === 0 ? 'mt-8' : 'mt-n4',
    colSize: index === 0 ? 2 : fillSpace ? 6 : 2,
    displayIf: displayIf(
      { path: 'grossReceiptsBasisOverride', source: 'local', permittedValues: [true] },
    ),
  }
  if (index === 0) {
    const grossReceiptsEstimates: FormComponent<QuoteFormSource> = {
      type: 'static',
      data: {
        path: 'grossReceiptsEstimates.0.grossReceipts',
        source: 'responses',
      },
      class: index === 0 ? 'mt-8' : 'mt-n4',
      colSize: 4,
      displayIf: displayIf(
        { path: 'grossReceiptsBasisOverride', source: 'local', permittedValues: [true] },
      ),
      formatter: 'currency',
    }
    if (!hideLabels) {
      effectiveDate.label = 'Effective Date'
      expirationDate.label = 'Expiration Date'
      grossReceipts.label = 'Gross Receipts'
      mileage.label = 'Mileage'
      grossReceiptsEstimates.label = 'Upcoming Gross Receipts Estimate'
    }
    return [effectiveDate, expirationDate, grossReceipts, mileage, grossReceiptsEstimates]
  } else {
    if (!hideLabels) {
      effectiveDate.label = 'Effective Date'
      expirationDate.label = 'Expiration Date'
      grossReceipts.label = 'Gross Receipts'
      mileage.label = 'Mileage'
    }
    return [effectiveDate, expirationDate, grossReceipts, mileage]
  }
}

export const minimumEarnedPremiumItems: DropdownItemObject[] = [
  { text: 'Not Included', value: '0' },
  { text: '15%', value: '15' },
  { text: '25%', value: '25' },
]

export const minimumEarnedPremiumMap = minimumEarnedPremiumItems.reduce<Record<string, string>>((acc, item) => Object.assign(acc, { [item.value as string]: item.text }), {})

export const permitIfBroker: Condition<'store'> = {
  path: 'userRole',
  source: 'store',
  store: 'authentication',
  permittedValues: ['BROKER'],
}

export const restrictIfBroker: Condition<'store'> = {
  path: 'userRole',
  source: 'store',
  store: 'authentication',
  restrictedValues: ['BROKER'],
}

export const agencyInStore: Condition<'store'> = {
  path: 'agency',
  source: 'store',
  store: 'appContext',
  restrictedValues: [null],
}

export const agencyNotInStore: Condition<'store'> = {
  path: 'agency',
  source: 'store',
  store: 'appContext',
  permittedValues: [null],
}

export const agencyName: FormComponent<QuoteFormSource> = {
  label: 'Agency Name',
  data: {
    path: 'agencyName',
    source: 'local',
  },
  default: {
    type: 'data',
    data: {
      path: 'agencyName',
      source: 'responses',
    },
  },
  type: 'text',
  rules: ['required'],
  colSize: 4,
  includeIf: includeIfPrimary,
}

export const pniFields: FormComponent<QuoteFormSource>[] = [
  {
    label: 'Primary Named Insured',
    data: {
      path: 'PNI',
      source: 'local',
    },
    default: {
      type: 'data',
      data: {
        path: 'PNI',
        source: 'responses',
      },
    },
    type: 'text',
    rules: ['required'],
    colSize: 6,
    includeIf: includeIfPrimary,
  },
  {
    type: 'computed',
    data: {
      path: 'completePNI',
      source: 'local',
    },
    calculation: {
      name: 'completePNI',
    },
  },
  {
    label: 'DBA',
    data: {
      path: 'DBA',
      source: 'local',
    },
    default: {
      type: 'data',
      data: {
        path: 'DBA',
        source: 'responses',
      },
    },
    type: 'text',
    colSize: 6,
    includeIf: includeIfPrimary,
  },
]

export const terminalAddressFields: FormComponent<QuoteFormSource>[] = [
  {
    label: 'Does the terminal / garaging address differ from the insured mailing address?',
    type: 'radio',
    buttons: {
      preset: 'boolean',
    },
    data: {
      path: 'terminalAddress.response',
      source: 'local',
    },
    default: {
      type: 'data',
      data: {
        path: 'terminalAddress.response',
        source: 'responses',
      },
    },
    includeIf: includeIfPrimary,
    rules: ['radioBooleanRequired'],
    colSize: 12,
  },
  {
    data: {
      path: 'terminalAddress.address',
      source: 'local',
    },
    type: 'computed',
    calculation: {
      name: 'echo',
      params: {
        valueData: {
          path: 'terminalAddress.address',
          source: 'responses',
        },
      },
    },
  },
  {
    data: {
      path: 'terminalAddress.address2',
      source: 'local',
    },
    type: 'computed',
    calculation: {
      name: 'echo',
      params: {
        valueData: {
          path: 'terminalAddress.address2',
          source: 'responses',
        },
      },
    },
  },
  {
    data: {
      path: 'terminalAddress.city',
      source: 'local',
    },
    type: 'computed',
    calculation: {
      name: 'echo',
      params: {
        valueData: {
          path: 'terminalAddress.city',
          source: 'responses',
        },
      },
    },
  },
  {
    data: {
      path: 'terminalAddress.state',
      source: 'local',
    },
    type: 'computed',
    calculation: {
      name: 'echo',
      params: {
        valueData: {
          path: 'terminalAddress.state',
          source: 'responses',
        },
      },
    },
  },
  {
    data: {
      path: 'terminalAddress.zip',
      source: 'local',
    },
    type: 'computed',
    calculation: {
      name: 'echo',
      params: {
        valueData: {
          path: 'terminalAddress.zip',
          source: 'responses',
        },
      },
    },
  },
  {
    data: {
      path: 'terminalAddress',
      source: 'local',
    },
    type: 'address',
    persistValue: true,
    required: true,
    colSize: 12,
    includeIf: includeIfPrimary,
    displayIf: displayIf(
      { path: 'terminalAddress.response', source: 'local', permittedValues: [true] },
    ),
  },
]
