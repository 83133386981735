import {
  User as CoreUser,
  SchemaSettings_RoleName,
} from '@policyfly/protobuf'
import { User as PatrickUser } from '@policyfly/protobuf/patrick'

import type { RoleName } from '@policyfly/types/user'
import type { UnifiedMembershipUser } from 'types/user'

/**
 * Converts Protobuf {@link CoreUser User} from grpc core definitions
 * to equivalent Protobuf {@link PatrickUser User} from patrick definitions.
 */
export function coreUserToPatrickUser (user: CoreUser): PatrickUser {
  return PatrickUser.create({
    userId: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    bingoId: user.ulid,
  })
}

/**
 * Converts Protobuf {@link PatrickUser User} from patrick definitions
 * to equivalent Protobuf {@link CoreUser User} from grpc core definitions.
 */
export function patrickUserToCoreUser (user: PatrickUser): CoreUser {
  return CoreUser.create({
    id: user.userId,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    ulid: user.bingoId,
  })
}

/**
 * Converts Django {@link UnifiedMembershipUser}
 * to equivalent Protobuf {@link CoreUser User} from grpc core definitions.
 */
export function userJsonToCoreUser (user?: Partial<UnifiedMembershipUser> | null): CoreUser {
  return CoreUser.create({
    id: user?.id || 0,
    email: user?.email || '',
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    ulid: user?.bingoId || undefined,
  })
}

/**
 * Converts Django {@link UnifiedMembershipUser}
 * to equivalent Protobuf {@link PatrickUser User} from patrick definitions.
 */
export function userJsonToPatrickUser (user?: Partial<UnifiedMembershipUser> | null): PatrickUser {
  return PatrickUser.create({
    userId: user?.id || undefined,
    email: user?.email || '',
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    activated: user?.active_at || undefined,
    avatar: user?.avatar_url || undefined,
    isActive: user?.is_active || false,
    bingoId: user?.bingoId || undefined,
  })
}

/**
 * Converts Protobuf {@link CoreUser User} from grpc core definitions
 * to equivalent Django {@link UnifiedMembershipUser}.
 */
export function coreUserToUserJson (user?: CoreUser): UnifiedMembershipUser | null {
  if (!user) return null
  return {
    id: user.id || 0,
    email: user.email || '',
    first_name: user.firstName || '',
    last_name: user.lastName || '',
    avatar_url: '',
    is_active: false,
    active_at: '',
    isClerk: false,
    bingoId: user.ulid || undefined,
  } as UnifiedMembershipUser
}

/**
 * Converts Protobuf {@link PatrickUser User} from patrick definitions
 * to equivalent Django mentioned_user for comments.
 */
export function patrickUserToMention (user: PatrickUser): Pick<UnifiedMembershipUser, 'email' | 'first_name' | 'last_name'> {
  return {
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
  }
}

export function settingsSchemaRoleNameToRoleName (roleName: SchemaSettings_RoleName): RoleName | undefined {
  return settingsSchemaRoleNameToRoleNameMap.get(roleName)
}

export function roleNameToSettingsSchemaRoleName (roleName: RoleName): SchemaSettings_RoleName {
  return roleNameToSettingsSchemaRoleNameMap.get(roleName) ?? SchemaSettings_RoleName.ROLE_NAME_UNSPECIFIED
}

const settingsSchemaRoleNameToRoleNameMap = new Map<SchemaSettings_RoleName, RoleName | undefined>([
  [SchemaSettings_RoleName.ROLE_NAME_UNSPECIFIED, undefined],
  [SchemaSettings_RoleName.ROLE_NAME_PROGRAM_ADMIN, 'PROGRAM_ADMIN'],
  [SchemaSettings_RoleName.ROLE_NAME_QUALITY_CONTROLLER, 'QUALITY_CONTROLLER'],
  [SchemaSettings_RoleName.ROLE_NAME_UNDERWRITER, 'UNDERWRITER'],
  [SchemaSettings_RoleName.ROLE_NAME_READ_ONLY, 'READ_ONLY'],
  [SchemaSettings_RoleName.ROLE_NAME_TECHNICIAN, 'TECHNICIAN'],
  [SchemaSettings_RoleName.ROLE_NAME_BROKER, 'BROKER'],
  [SchemaSettings_RoleName.ROLE_NAME_ISSUING_AGENT, 'ISSUING_AGENT'],
])

const roleNameToSettingsSchemaRoleNameMap = new Map<RoleName | undefined, SchemaSettings_RoleName>(
  Array.from(settingsSchemaRoleNameToRoleNameMap.entries()).map(([key, value]) => [value, key]),
)
