import { acceptHMRUpdate } from 'pinia'

import type { StoreDefinition } from 'pinia'

export function handleChunkLoadError (err: unknown): void {
  if (err instanceof Error) {
    console.error(err)
    // ignore errors where we use the wrong path in development
    if (import.meta.env.DEV) {
      if (err.message.includes('Failed to fetch dynamically imported module')) return
      if (err.message.includes('Unknown variable dynamic import')) return
    }
    location.reload()
  }
}

/* istanbul ignore next: see https://pinia.esm.dev/cookbook/hot-module-replacement.html -- @preserve */
export function hotReloadStore (useStore: unknown): void {
  if (import.meta.hot) {
    // see https://github.com/vuejs/pinia/issues/2098
    import.meta.hot.accept(acceptHMRUpdate(useStore as StoreDefinition, import.meta.hot))
  }
}
