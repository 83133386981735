import * as Sentry from '@sentry/browser'
import axios, { isCancel } from 'axios'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'
import { useBridgeStore } from '@/stores/bridge'
import { useNotificationStore } from '@/stores/notification'

import router from '@/router'
import routeNames from '@/router/routeNames'

import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

export const requestInterceptor: (request: InternalAxiosRequestConfig) => InternalAxiosRequestConfig = (request) => {
  const apiStore = useApiStore()
  if (apiStore.spaToken) {
    request.headers.setAuthorization(`Token ${apiStore.spaToken}`)
  }
  // Unless set by config, calculate baseURL per-request to avoid having to reload the page to switch programs
  if (!request.baseURL) {
    const authenticationStore = useAuthenticationStore()
    const slug = authenticationStore.slug
    request.baseURL = slug ? `/${slug}/api/v1` : '/api/v1'
  }
  return request
}

export const disablePreflightTransformer: <T>(data: T, headers: Record<string, string>) => T = (data, headers) => {
  if (headers) delete headers.Authorization
  return data
}

export const responseInterceptor: (value: AxiosResponse) => AxiosResponse = (response) => {
  const { data } = response || {}
  if (data?.pf_v1?.has_warning) {
    const bridgeStore = useBridgeStore()
    bridgeStore.warnings = data.pf_v1.warning
  }
  return response
}

export const responseErrorInterceptor: <T extends AxiosError>(error: T) => Promise<T> = (error) => {
  if (isCancel(error)) return Promise.reject(error)
  const authenticationStore = useAuthenticationStore()
  const notificationStore = useNotificationStore()
  const bridgeStore = useBridgeStore()
  const { response: errorResponse = { note: 'No Error Response, probably an internal Axios error', status: -1 } } = error
  if (!errorResponse.status) { // Network error
    notificationStore.setDefaultSnackbar({
      type: 'error',
      msg: 'Network Error: Please Try Again',
    })
  } else if (errorResponse.status === 401) { // Unauthorized
    // eslint-disable-next-line no-console
    console.log('Received 401 response, redirecting to Login')
    authenticationStore.$reset()
    const apiStore = useApiStore()
    apiStore.logout()
    router.replace({ name: routeNames.LOGIN })
  } else if (errorResponse.status === 422) { // Unprocessable Entity (external or bridge error)
    bridgeStore.errors = (errorResponse as unknown as NonNullable<AxiosError<{ pf_v1: { error?: string[] } }>['response']>).data.pf_v1.error || []
  } else {
    // don't collapse this into an `else if`, istanbul can't skip it
    /* istanbul ignore next: sentry is not installed in tests -- @preserve */
    if (import.meta.env.VITE_DEVTOOLS === 'true' && Sentry) {
      // Unhandled Exceptions
      Sentry.withScope((scope) => {
        scope.setUser({
          email: authenticationStore.email,
          role: authenticationStore.userRole,
          program: authenticationStore.programId,
          userId: authenticationStore.userId,
        })
        scope.setExtra('response', JSON.stringify(errorResponse))
        Sentry.captureException(error)
      })
    }
  }
  return Promise.reject(error)
}

axios.interceptors.request.use(requestInterceptor)
axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor)
