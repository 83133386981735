/**
 * Programmatic names for routes. Use these names, *not* paths, whenever routing in the application, so that paths can be changed without breaking routing.
 * Previously, some components depended on the name of their route to populate their user-facing page title field.
 * This is not what routes names are for.
 * This behavior is being phased out, but it's a good idea not to change the actual name content here without checking the relevant components first to make sure something won't accidentally be propagated to the user in an unexpected way.
 */
const routeNames = {
  LOGIN: 'Login',
  NO_PROGRAMS: 'noPrograms',
  HOME: 'home',
  RELEASE_NOTES: 'releaseNotes',
  TASKS: 'tasks',
  AGENCY_LIST: 'agency',
  AGENCY_DETAIL: 'agencyDetail',
  MY_AGENCIES: 'myAgencies',
  APPLICATION_EDIT: 'applicationEdit',
  APPLICATION_REVIEW: 'applicationDetails',
  APPLICATION_FEED: 'applicationFeed',
  APPLICATION_PRINT: 'applicationPrint',
  APPLICATION_ATTACHMENTS: 'applicationAttachments',
  APPLICATION_SUBMIT_QUOTES: 'applicationSubmitQuotes',
  APPLICATION_POLICY_DOCS: 'applicationPolicyDocs',
  APPLICATION_REVIEW_QUOTES: 'applicationReviewQuotes',
  APPLICATION_ISSUE_BINDER: 'applicationIssueBinder',
  APPLICATION_ISSUE_POLICY: 'applicationIssuePolicy',
  APPLICATION_SUBMIT_ENDORSEMENT: 'applicationSubmitEndorsement',
  APPLICATION_SUBMIT_ENDORSEMENT_QUOTE: 'applicationSubmitEndorsementQuote',
  APPLICATION_SUBMIT_CANCELLATION_QUOTE: 'applicationSubmitCancellationQuote',
  APPLICATION_EDIT_PREMIUM: 'applicationEditPremium',
  DOCUMENT_DOCUMENTSLIST: 'documentList',
  DOCUMENT_NEW: 'documentNew',
  DOCUMENT_REVISIONSLIST: 'documentRevisionsList',
  DOCUMENT_REVISION: 'documentRevision',
  DOCUMENT_REVISION_NEW: 'documentRevisionNew',
  USER_USERSLIST: 'usersList',
  USER_CREATE: 'userCreate',
  USER_VIEW: 'userView',
  REGISTER: 'register',
  ACCOUNT_INDEX: 'account',
  ACCOUNT_LOGOUT: 'accountLogout',
  SIGNATURE: 'signature',
  SIGNATURE_DETAILS: 'signatureDetails',
  SIGNATURE_SIGN: 'signatureSign',
  SIGNATURE_COMPLETE: 'signatureComplete',
  SIGNATURE_EXPIRED: 'signatureExpired',
  FORGOT_PASSWORD: 'forgotPassword',
  FORGOT_PASSWORD_RESET_PASSWORD: 'forgotPasswordResetPassword',
  FORGOT_PASSWORD_CONFIRM_CODE: 'forgotPasswordConfirmCode',
  POLICY_FEED: 'policyFeed',
  POLICY_REVIEW: 'policyDetails',
  POLICY_ATTACHMENTS: 'policyAttachments',
  POLICY_POLICY_DOCS: 'policyPolicyDocs',
  POLICY_PRINT: 'policyPrint',
  RATING: 'rating',
  REPORTING: 'reporting',
  ADMIN_INDEX: 'adminIndex',
  ADMIN_SANDBOX: 'adminSandbox',
  ADMIN_FORM_SANDBOX: 'adminFormSandbox',
  PRIVACY: 'privacy',
  TERMS: 'terms',
} as const

export default routeNames
